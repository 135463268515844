import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Big from 'big.js';
import styled from 'styled-components';

import { getProjectOrders } from '../../../../store/reducers/order/order';
import {
  getDeleteRequestState,
  getTargetRowById,
  getSplitToRows,
} from '../../../../store/reducers/target/targetRows';
import { getIsOuterBarOpen } from '../../../../store/reducers/ui';

import { fetchOrdersForProject } from '../../../../store/actions';
import {
  requestDeleteTargetRow,
  TargetRow,
  requestTargetRowsForProject,
} from '../../../../store/actions/target/targetRow';

import useRemoteData from '../../../../hooks/useRemoteData';
import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import { TagsContainer } from '../../../../components/Analysis/AnalysisTags';
import { IconButton } from '../../../../components/Buttons';
import Cell, { MoneyCell } from '../../../../components/Cell';
import Tooltip from '../../../../components/Tooltip';

import * as big from '../../../../utils/big';
import CAN, {
  CaslPaymentProgramRowRequestParams,
} from '../../../../utils/caslUserPermissions';
import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

import {
  IconSplitRow,
  IconSuffix,
  IconToLinkedEntity,
} from '../../../../assets';
import { IconDelete } from '../../../../assets/svg';

import { generateUrl, routes } from '../../../../routes';
import AnalysisTags from '../AnalysisTags';
import Checkbox from './Checkbox';
import { StyledIcon } from './Target';

type TargetedRowProps = {
  row: TargetRow;
  disableCheckbox?: boolean;
};

const TargetedRow = ({
  row: {
    id: targetRowId,
    description,
    quantity,
    unit,
    unitPrice,
    totalPrice,
    isDeletable,
    isDisabled,
    isSplitFrom,
    isAntiRow,
    analysisListItemIds,
  },
  disableCheckbox,
}: TargetedRowProps) => {
  const suffixAlt = useTxt('target.table.alt.suffix');
  const outerBarOpen = useSelector(getIsOuterBarOpen());
  const history = useHistory();

  const requestState = useSelector(getDeleteRequestState(targetRowId));

  const { projectId, viewMode } = useRouteParams();
  const splitFrom = useSelector(getTargetRowById(isSplitFrom ?? ''));

  const orders =
    useRemoteData(
      getProjectOrders(projectId),
      fetchOrdersForProject(projectId)
    ) ?? [];

  const splitTo =
    useRemoteData(
      getSplitToRows(projectId, targetRowId),
      requestTargetRowsForProject({ projectId })
    ) ?? [];

  const isButtonDisabled = requestState !== 'NotAsked';

  const dispatch = useDispatch();

  const ability = new CaslPaymentProgramRowRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  const onDeleteTargetRow = () => {
    dispatch(requestDeleteTargetRow({ targetRowId }));
  };

  const colSpanLength = () => {
    if (viewMode === 'edit' && !outerBarOpen) {
      return 4;
    }

    if (viewMode === 'edit' && outerBarOpen) {
      return 3;
    }

    if (viewMode === 'normal' && outerBarOpen) {
      return 3;
    }

    if (viewMode === 'receive') {
      return 5;
    }

    if (viewMode === 'normal' && !outerBarOpen) {
      return 4;
    }

    return 4;
  };

  const removeText = useTxt('order.inputs.Remove');

  const splitToTargetRowInfo = splitTo
    .map((row) => {
      const order = orders.find((o) => o.id === row.orderId);

      return `${row.description} (${order?.visibleCode}, ${order?.name})`;
    })
    .join(', ');

  const splitFromOrder = orders.find((o) => o.id === splitFrom?.orderId);

  const splitTargetTip = useTxt(
    'order.targetMode.splitIcon.tooltip.splitFrom',
    {
      targetRowInfo: `${splitFrom?.referenceNumber ?? ''} ${
        splitFrom?.description
      } (${splitFromOrder?.visibleCode}, ${splitFromOrder?.name})`,
    }
  );

  const disabledSplitTargetTip = useTxt(
    'order.targetMode.splitIcon.tooltip.disabled',
    { targetRowInfo: splitToTargetRowInfo }
  );

  const moveToTargetViewTip = useTxt(
    'order.targetMode.moveToTargetView.tooltip'
  );

  if (isAntiRow) {
    return null;
  }

  const navigateToSplitTargetView = () => {
    const splitIds: string[] = [targetRowId];

    if (isSplitFrom && isDisabled && splitFrom) {
      splitIds.push(splitFrom?.id, ...splitTo.map((row) => row.id));
    } else if (isSplitFrom && splitFrom) {
      splitIds.push(splitFrom?.id);
    } else {
      splitIds.push(...splitTo.map((row) => row.id));
    }

    if (splitIds.length > 0) {
      history.push(
        generateUrl({
          route: routes.TARGET_WITH_TARGET_ROW_FOCUSED,
          projectId,
          targetRowIds: splitIds.toString(),
        })
      );
    }
  };

  const onSplitIconPress = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      navigateToSplitTargetView();
    }
  };

  const tip = () => {
    if (isSplitFrom && isDisabled) {
      return `${splitTargetTip} & ${disabledSplitTargetTip}`;
    }

    if (isSplitFrom) {
      return splitTargetTip;
    }

    return disabledSplitTargetTip;
  };

  return (
    <Tr data-testid={`targeted-row-${targetRowId}`} isDisabled={isDisabled}>
      {viewMode === 'edit' ? <SuffixCell /> : null}
      <SuffixCell align="center">
        <SuffixCircle>
          <img src={IconSuffix} alt={suffixAlt} />
        </SuffixCircle>
      </SuffixCell>
      <StyledCellWithLeftBorder
        contentContainer={StyledContainer}
        isDisabled={isDisabled}
      >
        {viewMode === 'edit' ? (
          <Checkbox
            targetRowId={targetRowId}
            disabled={!allowedUser || disableCheckbox || isDisabled}
          />
        ) : null}
        <DescriptionSpan>{description}</DescriptionSpan>
        {isSplitFrom || isDisabled ? (
          <Tooltip tip={tip()} className="hoverable-tooltip" delayHide={50}>
            <StyledIcon
              src={IconSplitRow}
              alt="split_icon"
              width={16}
              height={16}
              onKeyPress={onSplitIconPress}
              onClick={onSplitIconPress}
            />
          </Tooltip>
        ) : null}
        <Link
          to={generateUrl({
            route: routes.TARGET_WITH_TARGET_ROW_FOCUSED,
            projectId,
            targetRowIds: targetRowId,
          })}
        >
          <Tooltip tip={moveToTargetViewTip}>
            <StyledIcon src={IconToLinkedEntity} alt="link_to_target_view" />
          </Tooltip>
        </Link>
      </StyledCellWithLeftBorder>
      {outerBarOpen ? (
        <StyledCell align="right">
          {`${big.amountFormat(quantity || new Big(0), 0)} ${unit}`}
        </StyledCell>
      ) : (
        <>
          <StyledCell align="right">
            {big.amountFormat(quantity || new Big(0))}
          </StyledCell>
          <StyledCell align="center">{unit}</StyledCell>
        </>
      )}
      <StyledMoneyCell
        value={unitPrice || new Big(0)}
        decimals={outerBarOpen ? 0 : undefined}
      />
      <StyledMoneyCell value={totalPrice} decimals={outerBarOpen ? 0 : 2} />
      <StyledCell colSpan={colSpanLength()} />
      {outerBarOpen || viewMode === 'receive' ? null : (
        <>
          <StyledCell align="center" contentContainer={TagsContainer}>
            <AnalysisTags
              targetRowId={targetRowId}
              analysisListItemIds={analysisListItemIds}
            />
          </StyledCell>
        </>
      )}
      <StyledCell colSpan={2} />
      {viewMode === 'edit' ? (
        <StyledCell align="center">
          {isDeletable ? (
            <IconButton
              icon={IconDelete}
              onClick={onDeleteTargetRow}
              aria-label={removeText}
              disabled={isButtonDisabled}
            />
          ) : null}
        </StyledCell>
      ) : null}
    </Tr>
  );
};

type TrProps = {
  isDisabled?: boolean;
};

const Tr = styled.tr<TrProps>`
  position: relative;
  background: ${(props) =>
    props.isDisabled
      ? props.theme.color.graphiteB76
      : props.theme.color.whisper};
  color: ${(props) =>
    props.isDisabled ? props.theme.color.graphiteB48 : props.theme.color.pitch};
`;

export const TargetCell = styled(Cell)`
  color: ${({
    theme: {
      color: { purple },
    },
  }) => purple};
`;

const StyledCell = styled(Cell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;

const StyledCellWithLeftBorder = styled(Cell)<TrProps>`
  border-bottom: 1px solid transparent;
  padding-left: ${({ theme: { margin } }) => margin[16]};
  background: linear-gradient(white, white) left/8px 100% no-repeat
    ${(props) =>
      props.isDisabled
        ? props.theme.color.graphiteB76
        : props.theme.color.whisper};
  border-image: linear-gradient(
      to right,
      white 9px,
      ${({ theme: { color } }) => color.graySuit} 9px 100%
    )
    1;
`;

const StyledContainer = styled.div`
  padding: ${({ theme: { margin } }) => margin[8]} 0;
  display: flex;
  align-items: center;
`;

const StyledMoneyCell = styled(MoneyCell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;

const DescriptionSpan = styled.span`
  margin-left: ${({ theme: { margin } }) => margin[24]};
`;

export const SuffixCell = styled(Cell)`
  padding: 0;
  background-color: white;
`;

export const SuffixCircle = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme: { margin, color } }) => `
    height: ${margin[32]};
    width: ${margin[32]};
    background-color: ${color.graphiteB96A};
  `}
`;

export default TargetedRow;
