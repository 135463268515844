import {
  APIOrderRow,
  APIOrderRowPutBody,
  APIOrderRowPostBody,
  APIMultipleOrderRowsPostBody,
  RawAPIOrderRow,
  RawAPIUpdatedEntities,
  APIUpdatedEntities,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawOrderRow, mapRawUpdatedEntities } from '../../types/mappers';

import {
  makeAction,
  makeApiActions,
  ExtractActionTypes,
} from '../../utils/actionCreators';
import {
  PUT,
  BackendError,
  apiErrorHandlingWithDecode,
  GET,
  POST,
  DELETE,
} from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { Thunk, createAsyncThunk } from '../../utils/thunk';

import { AppState, AppThunkDispatch } from '../reducers';
import {
  getNextStatusId,
  getOrderRowById,
  toRawApiOrderRow,
  getOrderRows,
  getOrderRowsForWorkPackage,
  getOrderRowsForAnalysisRow,
  getOrderRowsDeleteRequest,
  getOrderRowsDeleteMultipleRequest,
} from '../reducers/orderRow';

export type OrderRowAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getOrderRowsStarted')<{ orderId: string }>(),
  ...makeAction('getOrderRowsFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getOrderRowsSuccess')<{
    orderId: string;
    orderRows: APIOrderRow[];
  }>(),
  ...makeAction('getOrderRowsForWorkPackageStarted')<{
    workPackageId: string;
  }>(),
  ...makeAction('getOrderRowsForWorkPackageFailure')<{
    workPackageId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getOrderRowsForWorkPackageSuccess')<{
    workPackageId: string;
    orderRows: APIOrderRow[];
  }>(),
  ...makeAction('getOrderRowsForAnalysisRowStarted')<{
    analysisRowId: string;
  }>(),
  ...makeAction('getOrderRowsForAnalysisRowFailure')<{
    analysisRowId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getOrderRowsForAnalysisRowSuccess')<{
    analysisRowId: string;
    orderRows: APIOrderRow[];
  }>(),

  ...makeApiActions('put', 'orderRow')<APIUpdatedEntities>(),
  ...makeApiActions('post', 'orderRow')<APIUpdatedEntities>(),
  ...makeAction('deleteOrderRowStarted')<{
    orderRowId: string;
  }>(),
  ...makeAction('deleteMultipleOrderRowsStarted')<{
    requestId: string;
    orderRowIds: string[];
  }>(),
  ...makeAction('deleteOrderRowSuccess')<APIUpdatedEntities>(),
  ...makeAction('deleteMultipleOrderRowsSuccess')<
    APIUpdatedEntities & {
      orderRowIds: string[];
      requestId: string;
    }
  >(),
  ...makeAction('deleteOrderRowFailure')<{
    orderRowId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('deleteMultipleOrderRowsFailure')<{
    orderRowIds: string[];
    requestId: string;
    error: BackendError | undefined;
  }>(),
};
export const {
  getOrderRowsStarted,
  getOrderRowsSuccess,
  getOrderRowsFailure,
  getOrderRowsForWorkPackageStarted,
  getOrderRowsForWorkPackageSuccess,
  getOrderRowsForWorkPackageFailure,
  getOrderRowsForAnalysisRowStarted,
  getOrderRowsForAnalysisRowSuccess,
  getOrderRowsForAnalysisRowFailure,
  putOrderRowStarted,
  putOrderRowSuccess,
  putOrderRowFailure,
  postOrderRowStarted,
  postOrderRowSuccess,
  postOrderRowFailure,
  deleteOrderRowStarted,
  deleteOrderRowSuccess,
  deleteOrderRowFailure,
  deleteMultipleOrderRowsStarted,
  deleteMultipleOrderRowsSuccess,
  deleteMultipleOrderRowsFailure,
} = actionCreators;

const requestGetOrderRows = (orderId: ID) =>
  GET<RawAPIOrderRow[]>(`v1/orders/${orderId}/order-rows`).then((response) =>
    response.map(mapRawOrderRow)
  );

const requestGetOrderRowsForWorkPackage = (workPackageId: ID) =>
  GET<RawAPIOrderRow[]>(
    `v1/work-packages/${workPackageId}/order-rows`
  ).then((response) => response.map(mapRawOrderRow));

export const fetchOrderRowsForOrder = (orderId: ID) =>
  createAsyncThunk(requestGetOrderRows, {
    args: [orderId],
    isPending: flow(getOrderRows({ orderId }), remoteData.isLoading),
    initialAction: getOrderRowsStarted({ orderId }),
    failureActionCreator: (error) =>
      getOrderRowsFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (orderRows) =>
      getOrderRowsSuccess({ orderId, orderRows }),
  });

export const fetchOrderRowsForWorkPackage = (workPackageId: ID) =>
  createAsyncThunk(requestGetOrderRowsForWorkPackage, {
    args: [workPackageId],
    isPending: flow(
      getOrderRowsForWorkPackage({ workPackageId }),
      remoteData.isLoading
    ),
    initialAction: getOrderRowsForWorkPackageStarted({ workPackageId }),
    failureActionCreator: (error) =>
      getOrderRowsForWorkPackageFailure({
        workPackageId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (orderRows) =>
      getOrderRowsForWorkPackageSuccess({ workPackageId, orderRows }),
  });

const requestOrderRowsForAnalysisRow = (analysisRowId: ID) =>
  GET<RawAPIOrderRow[]>(
    `v1/custom-fields/list-items/${analysisRowId}/order-rows`
  ).then((response) => response.map(mapRawOrderRow));

export const fetchOrderRowsForAnalysisRow = (analysisRowId: string): Thunk =>
  createAsyncThunk(requestOrderRowsForAnalysisRow, {
    args: [analysisRowId],
    isPending: flow(
      getOrderRowsForAnalysisRow(analysisRowId),
      remoteData.isLoading
    ),
    initialAction: getOrderRowsForAnalysisRowStarted({ analysisRowId }),
    failureActionCreator: (error) =>
      getOrderRowsForAnalysisRowFailure({
        analysisRowId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (orderRows) =>
      getOrderRowsForAnalysisRowSuccess({ analysisRowId, orderRows }),
  });

const putOrderRow = (orderRowId: ID, orderRow: APIOrderRowPutBody) =>
  PUT<RawAPIUpdatedEntities>(`v1/order-rows/${orderRowId}`, orderRow).then(
    mapRawUpdatedEntities
  );

export const updateOrderRow = (
  orderRowId: ID,
  orderRow: APIOrderRowPutBody
): Thunk => (dispatch) => {
  dispatch(putOrderRowStarted());

  putOrderRow(orderRowId, orderRow).then(
    (updatedEntities) => {
      dispatch(putOrderRowSuccess(updatedEntities));
    },
    (error) => {
      dispatch(putOrderRowFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};

const postOrderRow = (orderRow: APIOrderRowPostBody) =>
  POST<RawAPIUpdatedEntities>('v1/order-rows', orderRow);
export const createOrderRow = (orderRow: APIOrderRowPostBody): Thunk => (
  dispatch,
  _
) => {
  dispatch(postOrderRowStarted());

  postOrderRow(orderRow)
    .then(mapRawUpdatedEntities)
    .then(
      (updatedEntities) => {
        dispatch(postOrderRowSuccess(updatedEntities));
      },
      (error) => {
        dispatch(postOrderRowFailure(apiErrorHandlingWithDecode(error)));
      }
    );
};

const postMultipleOrderRows = (body: APIMultipleOrderRowsPostBody) =>
  POST<RawAPIUpdatedEntities>('v1/order-rows/multiple', body).then(
    mapRawUpdatedEntities
  );
export const createMultipleOrderRows = (
  body: APIMultipleOrderRowsPostBody,
  successCallback?: (updated: APIUpdatedEntities) => void
): Thunk => (dispatch, _) => {
  dispatch(postOrderRowStarted());

  postMultipleOrderRows(body).then(
    (updatedEntities) => {
      dispatch(postOrderRowSuccess(updatedEntities));

      if (successCallback) {
        successCallback(updatedEntities);
      }
    },
    (error) => {
      dispatch(postOrderRowFailure(apiErrorHandlingWithDecode(error)));
    }
  );
};

export const makeDeleteOrderRowApiRequest = (orderRowId: ID) =>
  DELETE<RawAPIUpdatedEntities>(`v1/order-rows/${orderRowId}`).then(
    mapRawUpdatedEntities
  );

export const makeDeleteMultipleOrderRowsApiRequest = (orderRowIds: ID[]) =>
  POST<RawAPIUpdatedEntities>('v1/order-rows/delete-multiple', {
    orderRowIds,
  }).then(mapRawUpdatedEntities);

export const deleteOrderRow = (orderRowId: ID): Thunk => (dispatch) => {
  dispatch(
    createAsyncThunk(makeDeleteOrderRowApiRequest, {
      args: [orderRowId],
      isPending: flow(
        getOrderRowsDeleteRequest(orderRowId),
        remoteData.isLoading
      ),
      initialAction: deleteOrderRowStarted({ orderRowId }),
      successActionCreator: (updatedEntities) =>
        deleteOrderRowSuccess({
          ...updatedEntities,
        }),
      failureActionCreator: (error) =>
        deleteOrderRowFailure({
          orderRowId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};

export const deleteMultipleOrderRows = (
  orderRowIds: ID[],
  requestId: string
): Thunk => (dispatch) => {
  dispatch(
    createAsyncThunk(makeDeleteMultipleOrderRowsApiRequest, {
      args: [orderRowIds],
      isPending: flow(
        getOrderRowsDeleteMultipleRequest(requestId),
        remoteData.isLoading
      ),
      initialAction: deleteMultipleOrderRowsStarted({ orderRowIds, requestId }),
      successActionCreator: (updatedEntities) =>
        deleteMultipleOrderRowsSuccess({
          ...updatedEntities,
          orderRowIds,
          requestId,
        }),
      failureActionCreator: (error) =>
        deleteMultipleOrderRowsFailure({
          orderRowIds,
          requestId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};

/* This is antipattern
 *
   I should have created OrderRow/StatusToggled action that would have pure
   action creator that called API and returned the next action. But because
   using action type creator script is in my opinion clumsy, I just created
   impure action creator that is impossible to test and calls other actions
   that do not descibe what happened.

   Also we don't have a place for API calls so it is easier just to call
   existing action. What I should have done:

   1. CreateActions:
    - OrderRow/StatusToggled/Requested
    - OrderRow/StatusToggled/Success
    - OrderRow/StatusToggled/Failed
   2. CreateAction creators
    - ToggleStatus(requiredState) => dispatch => dispatch(success/failure)
   3. Respond to actions in reducer
*/

export const toggleStatus = (orderRowId: string) => (
  dispatch: AppThunkDispatch,
  getState: () => AppState
) => {
  const appState = getState();
  const orderRow = getOrderRowById(orderRowId)(appState);
  const statusId = getNextStatusId(orderRowId, appState);

  if (!statusId || !orderRow) {
    return;
  }

  const {
    description,
    unit,
    quantity,
    unitPrice,
    updatedAt,
    rowNumber,
  } = toRawApiOrderRow(orderRow);
  dispatch(
    updateOrderRow(orderRowId, {
      description,
      unit,
      quantity,
      unitPrice,
      updatedAt,
      statusId,
      rowNumber,
    })
  );
};
