import React from 'react';

import Big from 'big.js';
import styled from 'styled-components';

import { OrderChangeLogEntry } from '../../../store/reducers/order/changeLogEntries';
import { TargetChangeLogEntry } from '../../../store/reducers/target/changeLogEntries';

import {
  DeltaBigCostValue,
  DeltaBigProfitValue,
} from '../../../components/BigValue';
import Cell from '../../../components/Cell';
import { getUserInitials } from '../../../components/Navigation/UserSettings';
import { SecondaryRow } from '../../../components/Table';
import {
  ThemeColor,
  UserInitialsBadge,
} from '../../../components/UserInitialsBadge';

import { dateTimeFormat } from '../../../utils/format';

import { IconArrowRight } from '../../../assets/svg';

import defaultTheme from '../../../styles/theme';
import { ActionStatusPill } from './ActionStatus';
import { RowTypeIndicator } from './RowTypeIndicator';

export function isOrderChangeLogEntry(
  entry: OrderChangeLogEntry | TargetChangeLogEntry
): entry is OrderChangeLogEntry {
  const properties = Object.keys(entry);

  return properties.includes('orderRowNo');
}

const ChangeLogEntry = (entry: OrderChangeLogEntry | TargetChangeLogEntry) => {
  const authorColor = defaultTheme.color.amethyst as ThemeColor;

  return (
    <StyledSecondaryRow
      key={`${
        isOrderChangeLogEntry(entry)
          ? 'orderChangeLogEntry'
          : 'targetChangeLogEntry'
      }-${entry.id}`}
    >
      <Cell align="left">
        <UserInitialsBadge backgroundColor={authorColor} type="button">
          {getUserInitials(entry.userName)}
        </UserInitialsBadge>
        <UserName>
          <b>{entry.userName}</b>
          <br />
          {dateTimeFormat.format(entry.timestamp)}
        </UserName>
      </Cell>
      <Cell align="left">
        <RowTypeIndicator
          changeLowRowType={
            isOrderChangeLogEntry(entry)
              ? 'orderChangeLogEntry'
              : 'targetChangeLogEntry'
          }
        />
      </Cell>
      {isOrderChangeLogEntry(entry) ? (
        <StyledCell>{entry.orderRowDescription}</StyledCell>
      ) : (
        <TargetCell>
          {entry.referenceNo
            ? `${entry.referenceNo} ${entry.targetRowDescription}`
            : entry.targetRowDescription}
        </TargetCell>
      )}
      <Cell align="center">
        <ActionStatusPill
          isOrderRow={isOrderChangeLogEntry(entry)}
          status={entry.changeType}
          moveChangeInfo={entry.moveChangeInfo}
          quantityChangeInfo={entry.quantityChangeInfo}
          unitPriceChangeInfo={entry.unitPriceChangeInfo}
        />
      </Cell>
      <Cell>
        <IconArrowRight fill="black" />
      </Cell>
      <Cell align="right">
        {isOrderChangeLogEntry(entry) ? (
          <DeltaBigCostValue
            value={new Big(entry.differenceForOrderTotal)}
            decimals={2}
          />
        ) : (
          <DeltaBigProfitValue
            value={new Big(entry.differenceForOrderTotal)}
            decimals={2}
          />
        )}
      </Cell>
    </StyledSecondaryRow>
  );
};

const UserName = styled.div`
  padding-left: ${({ theme }) => theme.margin[10]};
`;

const TargetCell = styled(Cell)`
  color: ${({ theme }) => theme.color.purple};
  word-break: break-all;
`;

const StyledCell = styled(Cell)`
  word-break: break-all;
`;

const StyledSecondaryRow = styled(SecondaryRow)`
  /* stylelint-disable selector-max-type -- used in SecondaryRow-component */
  & + & > td {
    border-top: 0px;
  }
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export default ChangeLogEntry;
