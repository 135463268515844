import Big from 'big.js';

import * as api from '../../../utils/api';
import * as big from '../../../utils/big';
import { isDefined } from '../../../utils/general';
import normalizeBy from '../../../utils/normalizeBy';
import * as remoteData from '../../../utils/remoteData';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
  Selector,
} from '../utils';

import { ActionTypes as Action } from '../../actionTypes';
import { sortItems } from '../helpers/sort';
import { AppState } from '../index';
import { getActiveProjectId } from '../ui';
import { getSortOrders } from './sortRevenue';

export type Revenue = {
  id: string;
  projectId: string;
  paymentBatchGroupId: string | null;
  paymentProgramRowGroupId: string;

  batchCode: string;
  description: string;
  unit: string;

  quantity: Big;
  unitPrice: Big;
  vat: Big;
  actualizedBilling: Big;

  grossPrice: Big;
  netPrice: Big;

  status: 'Preliminary' | 'Planned' | 'Accepted' | 'Invoiced' | 'Paid';
  isBilled: boolean;
  isDeletable: boolean;

  billingDate: Date | string | null;

  analysisRowIds: string[];

  isDeleted: boolean;
  updatedAt: Date;

  predictionChangeFromLatest: Big;
  previousBillingDateFromLatest: Date | string | null;
};

type Mapping<A> = Partial<Record<string, A>>;

type Err = api.BackendError | undefined | string;
type RemoteData<A> = remoteData.RemoteData<A, Err>;

export type ProjectRevenue = RemoteData<Mapping<Revenue>>;
export type RevenueState = Mapping<ProjectRevenue>;

function revenueReducer(
  state: RevenueState = {},
  action: Action
): RevenueState {
  switch (action.type) {
    case 'GET_REVENUES_REQUESTED': {
      return { ...state, [action.payload.projectId]: remoteData.loading };
    }
    case 'GET_REVENUES_FAILURE': {
      const { projectId, error } = action.payload;

      const failure =
        error instanceof api.ApiError ? error.getBackendError() : undefined;

      return { ...state, [projectId]: remoteData.fail(failure) };
    }
    case 'GET_REVENUES_SUCCEEDED': {
      const { projectId, revenues } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.succeed(normalizeBy('id', revenues)),
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const revenues = action.payload?.revenues ?? [];

    return revenues.reduce((newState, updatedRevenue) => {
      const { projectId, id, isDeleted } = updatedRevenue;

      return {
        ...newState,
        [projectId]: remoteData.map(
          newState[projectId] ?? remoteData.notAsked,
          ({ [id]: _, ...projectRevenues }) =>
            isDeleted
              ? projectRevenues
              : { ...projectRevenues, [id]: updatedRevenue }
        ),
      };
    }, state);
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
}

export default revenueReducer;

export function getProjectRevenue(
  projectId: string
): Selector<RemoteData<Revenue[]>> {
  return ({ revenues: { paymentRows } }: AppState) =>
    remoteData.map(
      paymentRows[projectId] ?? remoteData.notAsked,
      (revenueMapping) => Object.values(revenueMapping).filter(isDefined)
    );
}

export function getRevenue({
  projectId,
  revenueId,
}: {
  projectId: string;
  revenueId: string;
}): Selector<Revenue | undefined> {
  return ({
    revenues: {
      paymentRows: { [projectId]: projectRevenues = remoteData.notAsked },
    },
  }: AppState) => {
    return remoteData.unwrap(projectRevenues, {
      unwrapper: ({ [revenueId]: revenue }) => revenue,
      defaultValue: undefined,
    });
  };
}

export type Summary = {
  total: string;
  billed: string;
  notBilled: string;
};
export function getRevenueSummary(
  projectId: string
): Selector<remoteData.RemoteData<Summary, Err>> {
  return (appState: AppState) =>
    remoteData.map(getProjectRevenue(projectId)(appState), (revenues) => {
      const { billed, notBilled } = revenues.reduce(
        (acc, { netPrice, isBilled }) =>
          isBilled
            ? { ...acc, billed: acc.billed.add(netPrice) }
            : { ...acc, notBilled: acc.notBilled.add(netPrice) },
        { billed: Big(0), notBilled: Big(0) }
      );

      return {
        total: big.format(billed.add(notBilled), 0),
        billed: big.format(billed, 0),
        notBilled: big.format(notBilled, 0),
      };
    });
}

export const getRevenueRows: Selector<remoteData.RemoteData<Revenue[]>> = (
  appState: AppState
) => {
  const projectId = getActiveProjectId(appState) ?? '';
  const remoteRevenues = getProjectRevenue(projectId)(appState);
  const sortOrder = getSortOrders(appState);

  const customComparators = {
    billingDate: (a: Revenue, b: Revenue) => {
      const dateA = a.billingDate ? new Date(a.billingDate) : new Date();
      const dateB = b.billingDate ? new Date(b.billingDate) : new Date();

      return dateA.getTime() - dateB.getTime();
    },
    batchCode: (a: Revenue, b: Revenue) =>
      a.batchCode.localeCompare(b.batchCode, 'fi', { numeric: true }),
  };

  return remoteData.map(remoteRevenues, (revenues) =>
    sortItems(revenues, sortOrder, customComparators)
  );
};

export function getRevenuesByIds({
  projectId,
  revenueIds,
}: {
  projectId: string;
  revenueIds: string[];
}): Selector<Revenue[]> {
  return ({
    revenues: {
      paymentRows: { [projectId]: projectRevenues = remoteData.notAsked },
    },
  }: AppState) => {
    return remoteData.unwrap(projectRevenues, {
      unwrapper: (revenues) => {
        const allRevenuesFiltered = Object.values(revenues)
          .filter(isDefined)
          .filter((row) => revenueIds.includes(row.id));

        return allRevenuesFiltered;
      },
      defaultValue: [],
    });
  };
}
