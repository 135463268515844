import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import styled from 'styled-components';

import { getProjectOrders } from '../../store/reducers/order/order';
import { getTargetRowHierarchyEntriesForProject } from '../../store/reducers/target/hierarchy';
import { getTargetRowsForProject } from '../../store/reducers/target/targetRows';
import {
  getRequestStateByProjectId,
  getTopics,
} from '../../store/reducers/topic';
import { getProjectWorkPackages } from '../../store/reducers/workPackage';

import * as Actions from '../../store/actions';

import useRemoteData from '../../hooks/useRemoteData';
import useTargetViewData from './hooks/useTargetViewData';

import Header from '../../components/Header';
import ProjectInfo from '../../components/ProjectInfo';
import Txt from '../../components/Txt';
import Summary from './components/Summary';
import TargetTable from './components/TargetTable';

import { routes, useParams } from '../../routes';

const ToolbarContainer = styled.div`
  height: ${(props) => props.theme.margin[32]};

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  line-height: ${(props) => props.theme.margin[32]};
`;

const TargetView = () => {
  const { projectId } = useParams(routes.TARGET);

  useRemoteData(
    getRequestStateByProjectId(projectId),
    Actions.fetchTopicsForProject(projectId)
  );

  const targetRowHierarchyEntries =
    useRemoteData(
      getTargetRowHierarchyEntriesForProject(projectId),
      Actions.getTargetRowHierarchyForProject({ projectId })
    ) ?? [];

  const targetRows =
    useRemoteData(
      getTargetRowsForProject({ projectId }),
      Actions.requestTargetRowsForProject({ projectId })
    ) ?? [];

  const topics = useSelector(getTopics, shallowEqual);

  const orders =
    useRemoteData(
      getProjectOrders(projectId),
      Actions.fetchOrdersForProject(projectId)
    ) ?? [];

  const workPackages =
    useRemoteData(
      getProjectWorkPackages(projectId),
      Actions.fetchWorkPackagesForProject(projectId)
    ) ?? [];

  const data = useTargetViewData({
    targetRows,
    targetRowHierarchyEntries,
    orders,
    topics,
    workPackages,
  });

  return (
    <Container>
      <Header>
        <Txt id="target.header" />
      </Header>
      <ProjectInfo projectId={projectId} />
      <ToolbarContainer />
      <Summary projectId={projectId} />
      <TargetTable projectId={projectId} data={data} />
    </Container>
  );
};

const Container = styled.div`
  margin: ${({ theme }) => theme.margin[24]} ${({ theme }) => theme.margin[40]}
    0;
`;

export default TargetView;
