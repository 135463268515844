import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Big from 'big.js';

import { APIOrder, APITopic, APIWorkPackage } from '@customtypes/api';

import { getTargetViewFilters } from '../../../store/reducers/ui';

import * as Actions from '../../../store/actions';

import useTxt from '../../../hooks/useTxt';

import { isDefined, isNotNull } from '../../../utils/general';
import { searchFilter } from '../../../utils/search';

import { routes, useParams } from '../../../routes';

export type TargetRowOrTargetRowHierarchyEntry = {
  id: string;
  originalId: string;
  description: string | null;
  projectId: string;
  orderId: string | null;
  workPackageId: string | undefined;
  type: 'targetRow' | 'targetRowHierarchyEntry';
  quantity: Big | null;
  unit: string | null;
  unitPrice: Big | null;
  target: Big | null;
  referenceNo: string | null;
  topicId: string | null;
  orderCodeName: string | undefined;
  workSectionCodeName: string | undefined;
  isDeleted: boolean;
  isAntiRow: boolean;
  isSplitFrom: string | null;
  splitFrom:
    | (Actions.TargetRow & { orderVisibleCodeAndName: string })
    | null
    | undefined;
  splitTo:
    | (Actions.TargetRow & { orderVisibleCodeAndName: string })[]
    | null
    | undefined;
  isOriginal: boolean;
  isDisabled: boolean;
  targetRowHierarchyEntryId: string | null;
  isHighLighted?: boolean;
  subRows?: TargetRowOrTargetRowHierarchyEntry[] | undefined;
};

const sortingFunction = (
  a: TargetRowOrTargetRowHierarchyEntry,
  b: TargetRowOrTargetRowHierarchyEntry
) => {
  if (
    a.referenceNo &&
    a.referenceNo.trim().length > 0 &&
    b.referenceNo &&
    b.referenceNo.trim().length > 0
  ) {
    return (a.referenceNo ?? '').localeCompare(b.referenceNo ?? '');
  }

  if (a.referenceNo && a.referenceNo.trim().length > 0) {
    return 1;
  }

  if (b.referenceNo && b.referenceNo.trim().length > 0) {
    return -1;
  }

  return (a.description ?? '').localeCompare(b.description ?? '');
};

export default function useTargetViewData({
  targetRows,
  targetRowHierarchyEntries,
  topics,
  orders,
  workPackages,
}: {
  targetRows: Actions.TargetRow[];
  targetRowHierarchyEntries: Actions.TargetRowHierarchyEntry[];
  topics: APITopic[];
  orders: APIOrder[];
  workPackages: APIWorkPackage[];
}): TargetRowOrTargetRowHierarchyEntry[] {
  const { projectId } = useParams(routes.TARGET);

  const { targetRowIds, filterSearchWord } = useSelector(
    getTargetViewFilters(),
    shallowEqual
  );

  const useTargetRowIds = targetRowIds && targetRowIds.length > 0;

  const searchParameter = useTargetRowIds ? ' ' : filterSearchWord;

  const sectionTitleText = useTxt('target.noHierarchies.sectionTitle');

  const memoizedTree = useMemo(() => {
    const maxDepth = Math.max(
      ...targetRowHierarchyEntries.map((entry) => entry.depth)
    );

    const targetRowsWithCodeNames = targetRows.map((targetRow) => ({
      ...targetRow,
      orderCodeName: orders
        .filter((order) => order.id === targetRow.orderId)
        .map((order) => `${order.visibleCode} ${order.name}`)[0],
      workSectionCodeName: workPackages
        .filter((workPackage) => {
          return (
            workPackage.id ===
            topics.find((topic) => topic.id === targetRow.topicId)
              ?.workPackageId
          );
        })
        .map((wp) => `${wp.code} ${wp.name}`)[0],
    }));

    const filteredTargetRows = (useTargetRowIds
      ? targetRowsWithCodeNames.filter(
          (row) => targetRowIds && targetRowIds.includes(row.id)
        )
      : searchFilter(targetRowsWithCodeNames, searchParameter)
    ).filter((row) => row.isAntiRow === false);

    const filteredTargetRowIds = filteredTargetRows.map((row) => row.id);

    const filteredHierarchyEntrys = useTargetRowIds
      ? []
      : searchFilter(targetRowHierarchyEntries, searchParameter);

    const filteredHierarchyEntryIds = filteredHierarchyEntrys.map(
      (entry) => entry.id
    );

    const mappedTargetRows: TargetRowOrTargetRowHierarchyEntry[] = targetRowsWithCodeNames
      .map((targetRow) => {
        const splitFrom = targetRows.find(
          (row) => row.id === targetRow.isSplitFrom
        );

        const splitFromOrder = orders.find(
          (order) => order.id === splitFrom?.orderId
        );

        return {
          id: `targetRow-${targetRow.id}`,
          originalId: targetRow.id,
          description: targetRow.description,
          type: 'targetRow' as const,
          quantity: targetRow.quantity,
          unit: targetRow.unit,
          unitPrice: targetRow.unitPrice,
          referenceNo: targetRow.referenceNumber,
          target: targetRow.unitPrice
            ? targetRow.unitPrice.mul(targetRow.quantity ?? new Big(0))
            : new Big(0),
          topicId: targetRow.topicId,
          projectId: targetRow.projectId,
          orderId: targetRow.orderId,
          workPackageId: workPackages.find((workPackage) => {
            return (
              workPackage.id ===
              topics.find((topic) => topic.id === targetRow.topicId)
                ?.workPackageId
            );
          })?.id,
          orderCodeName: targetRow.orderCodeName,
          workSectionCodeName: targetRow.workSectionCodeName,
          splitFrom: splitFrom
            ? {
                ...splitFrom,
                orderVisibleCodeAndName: `${splitFromOrder?.visibleCode} ${splitFromOrder?.name}`,
              }
            : null,
          splitTo: targetRows
            .filter((row) => row.isSplitFrom === targetRow.id)
            .map((row) => ({
              ...row,
              orderVisibleCodeAndName: `${
                orders.find((order) => order.id === row.orderId)?.visibleCode
              } ${orders.find((order) => order.id === row.orderId)?.name}`,
            })),
          isDeleted: targetRow.isDeleted,
          isAntiRow: targetRow.isAntiRow,
          isSplitFrom: targetRow.isSplitFrom,
          isDisabled: targetRow.isDisabled,
          isOriginal: targetRow.isOriginal,
          isHighLighted:
            searchParameter.length > 0 &&
            filteredTargetRowIds.includes(targetRow.id),
          targetRowHierarchyEntryId: targetRow.targetRowHierarchyEntryId
            ? `hierarchyEntry-${targetRow.targetRowHierarchyEntryId}`
            : 'hierarchyEntry-none',
        };
      })
      .sort(sortingFunction);

    const mappedTargetRowHierarchyEntries: TargetRowOrTargetRowHierarchyEntry[] = targetRowHierarchyEntries
      .map((entry) => ({
        id: `hierarchyEntry-${entry.id}`,
        originalId: entry.id,
        description: entry.description,
        projectId: entry.projectId,
        orderId: null,
        workPackageId: undefined,
        type: 'targetRowHierarchyEntry' as const,
        quantity: entry.quantity,
        unit: entry.unit,
        unitPrice: entry.unitPrice,
        target: entry.totalAmount,
        referenceNo: entry.referenceNumber,
        topicId: null,
        orderCodeName: undefined,
        workSectionCodeName: undefined,
        isDeleted: entry.isDeleted,
        isAntiRow: false,
        isSplitFrom: null,
        isOriginal: true,
        splitFrom: null,
        isDisabled: false,
        splitTo: null,
        isHighLighted:
          searchParameter.length > 0 &&
          filteredHierarchyEntryIds.includes(entry.id),
        targetRowHierarchyEntryId: entry.parentId
          ? `hierarchyEntry-${entry.parentId}`
          : null,
      }))
      .sort(sortingFunction);

    const targetRowParentIds = filteredTargetRows
      .map((row) => row.targetRowHierarchyEntryId)
      .filter(isNotNull);

    const allEntryLowLevelIds = [
      ...new Set([...filteredHierarchyEntryIds, ...targetRowParentIds]),
    ];

    const allIncludingParents = collectAllParentIds(
      maxDepth,
      allEntryLowLevelIds,
      targetRowHierarchyEntries
    );

    const allIncludingChildren = collectAllChildIds(
      0,
      maxDepth,
      allEntryLowLevelIds,
      targetRowHierarchyEntries
    );

    const allFilteredEntryIds = [
      ...new Set([...allIncludingParents, ...allIncludingChildren]),
    ];

    const mappedAndFilteredHierarchyEntries = mappedTargetRowHierarchyEntries.filter(
      (entry) => allFilteredEntryIds.includes(entry.originalId)
    );

    const targetRowsForFilteredParents = targetRows
      .filter(
        (row) =>
          row.targetRowHierarchyEntryId &&
          allFilteredEntryIds.includes(row.targetRowHierarchyEntryId)
      )
      .map((row) => row.id);

    const allFilteredTargetRowIds = [
      ...new Set([
        ...filteredTargetRows.map((row) => row.id),
        ...targetRowsForFilteredParents,
      ]),
    ];

    const mappedAndFilteredTargetRows = mappedTargetRows.filter((row) =>
      allFilteredTargetRowIds.includes(row.originalId)
    );

    const allRows = [
      ...mappedAndFilteredHierarchyEntries,
      ...mappedAndFilteredTargetRows,
    ];

    const targetRowsWithoutParent = mappedTargetRows.filter(
      (row) => row.targetRowHierarchyEntryId === 'hierarchyEntry-none'
    );

    const targetRowsWithoutParentTotal = targetRowsWithoutParent.reduce(
      (acc, val) => {
        const unitPrice = val.unitPrice ?? new Big(0);
        const quantity = val.quantity ?? new Big(0);

        return acc.add(unitPrice.mul(quantity));
      },
      new Big(0)
    );

    const emptyHierarchyEntry: TargetRowOrTargetRowHierarchyEntry = {
      id: 'hierarchyEntry-none',
      originalId: 'none',
      description: sectionTitleText,
      type: 'targetRowHierarchyEntry' as const,
      projectId,
      orderId: null,
      workPackageId: undefined,
      quantity: null,
      unit: null,
      unitPrice: null,
      target: targetRowsWithoutParentTotal,
      referenceNo: null,
      topicId: null,
      orderCodeName: undefined,
      workSectionCodeName: undefined,
      isDeleted: false,
      isAntiRow: false,
      isSplitFrom: null,
      isOriginal: true,
      splitFrom: null,
      isDisabled: false,
      splitTo: null,
      targetRowHierarchyEntryId: null,
    };

    const parentRows = [
      ...mappedAndFilteredHierarchyEntries.filter(
        (row) => row.targetRowHierarchyEntryId === null
      ),
      emptyHierarchyEntry,
    ];

    const process = (
      node: TargetRowOrTargetRowHierarchyEntry | undefined
    ): TargetRowOrTargetRowHierarchyEntry | undefined => {
      if (!node) {
        return undefined;
      }

      return {
        ...node,
        subRows: allRows
          .filter((x) => x.targetRowHierarchyEntryId === node.id)
          .map(process)
          .filter(isDefined)
          .sort(sortingFunction),
      };
    };

    const tree = parentRows.map(process).filter(isDefined);

    return tree;
  }, [
    targetRowHierarchyEntries,
    useTargetRowIds,
    targetRows,
    searchParameter,
    sectionTitleText,
    projectId,
    targetRowIds,
    workPackages,
    orders,
    topics,
  ]);

  if (
    topics.length === 0 ||
    targetRows.length === 0 ||
    orders.length === 0 ||
    workPackages.length === 0
  ) {
    return [];
  }

  return memoizedTree;
}

// Searches all childIds until depth = 0
export const collectAllParentIds = (
  startDepth: number,
  childIds: string[],
  targetRowHierarchyEntries: Actions.TargetRowHierarchyEntry[]
): string[] => {
  const newIds = targetRowHierarchyEntries
    .filter((entry) => entry.depth === startDepth)
    .filter((entry) => entry.childIds.some((id) => childIds.includes(id)))
    .map((entry) => entry.id);
  const allIds = [...new Set([...childIds, ...newIds])];
  const nextDepth = startDepth - 1;

  if (nextDepth < 0) {
    return allIds;
  }

  return collectAllParentIds(nextDepth, allIds, targetRowHierarchyEntries);
};

// Searches all parentIds until depth = 0
export const collectAllChildIds = (
  currentDepth: number,
  maxDepth: number,
  parentIds: string[],
  targetRowHierarchyEntries: Actions.TargetRowHierarchyEntry[]
): string[] => {
  const newIds = targetRowHierarchyEntries
    .filter((entry) => entry.depth === currentDepth)
    .filter((entry) => entry.parentId && parentIds.includes(entry.parentId))
    .map((entry) => entry.id);
  const allIds = [...new Set([...parentIds, ...newIds])];
  const nextDepth = currentDepth + 1;

  if (nextDepth > maxDepth) {
    return allIds;
  }

  return collectAllChildIds(
    nextDepth,
    maxDepth,
    allIds,
    targetRowHierarchyEntries
  );
};
