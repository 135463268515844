import React, { useState } from 'react';

import { sortBy } from 'lodash';

import { APIProject } from '../../store/reducers/project';

import { ID } from '../../types/general';

import { searchFilter } from '../../utils/search';

import SearchInput from '../Input/SearchInput';
import {
  ProjectMenuContainer,
  ProjectMenuItem,
  ProjectsList,
} from './Components';
import UserRolesModal from './UserRolesModal';

type Props = {
  projects: APIProject[];
  onClickProject: (projectId: ID) => void;
  userRolesModalOpen: string | null;
  setUserRolesModalOpen: React.Dispatch<React.SetStateAction<string | null>>;
};

// TODO: Show last visited projects on top! This needs them to be stored to
// localStorage, so I'll not implement it now.

const ProjectMenu = ({
  projects,
  onClickProject,
  userRolesModalOpen,
  setUserRolesModalOpen,
}: Props) => {
  const [searchText, setSearchText] = useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const mappedProjects = projects.map((project) => {
    return {
      name: project.name,
      code: project.code,
    };
  });

  // don't include unnecessary properties (e.g. id)
  const filteredMappedProjects = searchFilter(mappedProjects, searchText);

  const filteredProjects = projects.filter((el) => {
    return filteredMappedProjects.some((entry) => {
      return entry.code === el.code && entry.name === el.name;
    });
  });

  const sortedAndFiltered = sortBy(filteredProjects, (entry) => entry.code);

  React.useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const selectedProject = projects.find(
    (project) => project.id === userRolesModalOpen
  );

  return (
    <>
      {userRolesModalOpen && selectedProject ? (
        <UserRolesModal
          closeModal={() => setUserRolesModalOpen(null)}
          project={selectedProject}
        />
      ) : null}
      <ProjectMenuContainer>
        <SearchInput
          value={searchText}
          onChange={onSearchInputChange}
          handleClearButtonClick={() => setSearchText('')}
          ref={inputRef}
        />
        <ProjectsList>
          {sortedAndFiltered.map((project) => {
            return (
              <ProjectMenuItem
                project={project}
                onClickProject={() => onClickProject(project.id)}
                toggleUserRoleMenu={() => setUserRolesModalOpen(project.id)}
                key={`project-menu-item-${project.id}`}
              />
            );
          })}
        </ProjectsList>
      </ProjectMenuContainer>
    </>
  );
};

export default ProjectMenu;
