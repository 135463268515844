import { Reducer } from 'redux';

import { APIProjectUser } from '../../types/api';

import { BackendError } from '../../utils/api';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';

import { AppState } from '.';

type SubState<T extends any> = Partial<
  Record<string, remoteData.RemoteData<T, BackendError | undefined>>
>;

type ProjectUsersState = {
  data: SubState<APIProjectUser[]>;
  updateRequests: SubState<undefined>;
  postUserRequests: SubState<APIProjectUser>;
};

const initialState: ProjectUsersState = {
  data: {},
  updateRequests: {},
  postUserRequests: {},
};

const projectUsersReducer: Reducer<ProjectUsersState, ActionTypes> = (
  state = initialState,
  action
): ProjectUsersState => {
  switch (action.type) {
    case 'GET_PROJECT_USERS_STARTED': {
      const { projectId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [projectId]: remoteData.loading,
        },
      };
    }
    case 'GET_PROJECT_USERS_FAILURE': {
      const { projectId, error } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [projectId]: remoteData.fail(error),
        },
      };
    }
    case 'GET_PROJECT_USERS_SUCCESS': {
      const { projectId, users } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [projectId]: remoteData.succeed(users),
        },
      };
    }
    case 'GET_ALL_USERS_STARTED': {
      return {
        ...state,
        data: {
          ...state.data,
          all: remoteData.loading,
        },
      };
    }
    case 'GET_ALL_USERS_FAILURE': {
      const { error } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          all: remoteData.fail(error),
        },
      };
    }
    case 'GET_ALL_USERS_SUCCESS': {
      const { users } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          all: remoteData.succeed(users),
        },
      };
    }
    case 'PUT_PROJECT_USER_RIGHTS_STARTED': {
      const { projectId } = action.payload;

      return {
        ...state,
        updateRequests: {
          ...state.updateRequests,
          [projectId]: remoteData.loading,
        },
      };
    }
    case 'PUT_PROJECT_USER_RIGHTS_FAILURE': {
      const { projectId, error } = action.payload;

      return {
        ...state,
        updateRequests: {
          ...state.updateRequests,
          [projectId]: remoteData.fail(error),
        },
      };
    }
    case 'PUT_PROJECT_USER_RIGHTS_SUCCESS': {
      // always returns all users with updated rights
      // doesn't return deleted users
      const { projectId, users } = action.payload;

      return {
        ...state,
        updateRequests: {
          ...state.updateRequests,
          [projectId]: remoteData.succeed(undefined),
        },
        data: {
          ...state.data,
          [projectId]: remoteData.succeed(users),
        },
      };
    }
    case 'POST_USER_STARTED': {
      const { requestId } = action.payload;

      return {
        ...state,
        postUserRequests: { [requestId]: remoteData.loading },
      };
    }
    case 'POST_USER_FAILURE': {
      const { requestId, error } = action.payload;

      return {
        ...state,
        postUserRequests: { [requestId]: remoteData.fail(error) },
      };
    }
    case 'POST_USER_SUCCESS': {
      const { requestId, user } = action.payload;

      // empty project users cache to force refetch
      return {
        ...state,
        data: {},
        postUserRequests: { [requestId]: remoteData.succeed(user) },
      };
    }
    default: {
      return state;
    }
  }
};

export default projectUsersReducer;

export const selectProjectUsers = (projectId: string) => ({
  projectUsers: {
    data: { [projectId]: projectUsers = remoteData.notAsked },
  },
}: AppState) => projectUsers;

export const selectAllUsers = () => ({
  projectUsers: {
    data: { all: users = remoteData.notAsked },
  },
}: AppState) => users;

export const selectUpdateRequest = (projectId: string) => ({
  projectUsers: {
    updateRequests: { [projectId]: updateRequest = remoteData.notAsked },
  },
}: AppState) => updateRequest;

export const selectPostUserRequest = (requestId: string) => ({
  projectUsers: { postUserRequests },
}: AppState) => {
  const request = postUserRequests[requestId] ?? remoteData.notAsked;

  return request;
};
