import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';

import {
  getAssignedArrivalRowsTotal,
  getInvoiceAssignedArrivalRowIdsByInvoiceId,
  getInvoiceAssignedArrivalRowIdsByInvoiceIdForSameOrder,
  getInvoiceAssignedArrivalRowIdsByInvoiceIdForOtherOrders,
} from '../../../../../store/reducers/arrivalRow';
import { useInvoiceHeaderAttachmentUrls } from '../../../../../store/reducers/invoiceAttachments';
import { InvoiceHeader } from '../../../../../store/reducers/invoiceHeader';
import { getOrderById } from '../../../../../store/reducers/order/order';
import { isTasksForInvoiceHeader } from '../../../../../store/reducers/tasks';
import { getIsOuterBarOpen } from '../../../../../store/reducers/ui';
import { selectCurrentUser } from '../../../../../store/reducers/user';

import {
  cancelInvoiceComplaintHeader,
  convertInvoiceHeader,
} from '../../../../../store/actions';
import { setOuterBarState } from '../../../../../store/actions/ui';

import { useArrivalRowSelection } from '../../../../../hooks/ui';
import useScrollTo from '../../../../../hooks/useScrollTo';
import useTxt from '../../../../../hooks/useTxt';

import { IconButton } from '../../../../../components/Buttons';
import {
  CellDiv,
  ToolTip as SimpleTooltip,
} from '../../../../../components/Cell';
import {
  DropDownList,
  DropDownItem,
} from '../../../../../components/DropdownList';
import Checkbox from '../../../../../components/Input/Checkbox';
import { InvoiceRow } from '../../../../../components/Table';

import { getBaseApiUrl } from '../../../../../utils/api';
import * as big from '../../../../../utils/big';
import CAN, {
  CaslProjectRequestParams,
  CaslPurchaseInvoiceHeadersHandleRequestParams,
} from '../../../../../utils/caslUserPermissions';
import { dateFormat } from '../../../../../utils/format';
import {
  INVOICE_HEADER_STATUS_NOT_HANDLED,
  INVOICE_HEADER_STATUS_ACCEPTED,
  INVOICE_HEADER_STATUS_COMPLAINT_FILED,
  INVOICE_HEADER_STATUS_CORRECTED,
  ALL_UNPROCESSED_INVOICE_STATUSES,
} from '../../../../../utils/general';
import { isClickOrKeyboardSelection } from '../../../../../utils/mouseOrKeyInteraction';
import { getInvoiceHeaderStatus } from './utils';

import {
  IconDown,
  IconRight,
  IconKebabMenu,
  IconAttachmentBlack,
  IconPaperAdd,
  IconPaperEdit,
  IconPapertrace,
  IconDecline,
  IconDelegate,
  IconChange,
  IconSplitScreen,
  IconPaperFix,
  IconNewTab,
} from '../../../../../assets/svg';

import { NoOverflowSpan } from '..';
import { routes, useParams, generateUrl } from '../../../../../routes';
import ArrivalRow, { StyledArrivalRow } from './ArrivalRow';
import ArrivalRowOrderGroup from './ArrivalRowOrderGroup';
import {
  AttachmentCount,
  IndicatorImg,
  TableBodyGap,
  InvoiceTableBody,
  ProcessingCell,
} from './Components';
import CorrectionModal from './CorrectionModal';
import DeclineInvoiceModal from './DeclineInvoiceModal';
import { FileInvoiceComplaintModal } from './FileInvoiceComplaintModal';
import InvoiceDelegationRow from './InvoiceDelegationRow';
import InvoiceModal from './InvoiceModals';
import {
  CompactCell,
  LeftPaddedCompactCell,
  UnitPriceCompactCell,
  RightPaddedCompactCell,
  SelectableInvoiceCell,
} from './LaariCells';
import ManualEntryModal from './ManualEntryModal/ManualEntryModal';
import MoveCostModal from './MoveCostModal/MoveCostModal';
import ProcessingTool from './ProcessingTool';

type Props = {
  invoiceHeader: InvoiceHeader;
  isSelected: boolean;
  isHandled?: boolean;
  onSelect?: (id: string) => void;
  isAllRendered: boolean;
  isOpen?: boolean;
  toggleExpandInvoice?: (id: string) => void;
  index: number;
  focus: boolean;
  setFocus: React.Dispatch<React.SetStateAction<number>>;
};

const Invoice = ({
  invoiceHeader,
  isSelected,
  isHandled,
  onSelect,
  isAllRendered,
  isOpen,
  toggleExpandInvoice,
  index,
  focus,
  setFocus,
}: Props) => {
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);
  const apiBaseUrl = getBaseApiUrl();

  const isTasks = useSelector(isTasksForInvoiceHeader(invoiceHeader.id));

  const { hash } = history.location;

  const { projectId, orderId } = useParams(routes.ORDER);

  const invoiceHeaderOrderVisibleCode = useSelector(
    getOrderById(invoiceHeader.orderId)
  )?.visibleCode;

  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const allSortedArrivalRowIds = useSelector(
    getInvoiceAssignedArrivalRowIdsByInvoiceId(invoiceHeader.id),
    (a, b) => a.join() === b.join()
  );

  const sortedArrivalRowIdsForThisOrder = useSelector(
    getInvoiceAssignedArrivalRowIdsByInvoiceIdForSameOrder(
      invoiceHeader.id,
      orderId
    ),
    (a, b) => a.join() === b.join()
  );

  const sortedArrivalRowIdsForOtherOrders = useSelector(
    getInvoiceAssignedArrivalRowIdsByInvoiceIdForOtherOrders(
      invoiceHeader.id,
      orderId
    ),
    shallowEqual
  );

  const dispatch = useDispatch();

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const [dropDownVisible, setDropDownVisibility] = useState(false);
  const [currentModal, setCurrentModal] = useState<string | undefined>();
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showManualEntryModal, setShowManualEntryModal] = useState(false);
  const [showFileComplaintModal, setShowFileComplaintModal] = useState(false);

  const [showInvoiceCorrectionModal, setInvoiceCorrectionModal] = useState(
    false
  );
  const attachmentUrls = useInvoiceHeaderAttachmentUrls(invoiceHeader.id);

  const [
    scrollToRef,
    setShouldScrollTo,
    hasScrolled,
  ] = useScrollTo<HTMLTableRowElement>();

  const abilityToEdit = new CaslProjectRequestParams(projectId ?? '');
  const allowedUserToEdit = CAN('write', abilityToEdit);

  const abilityToHandle = new CaslPurchaseInvoiceHeadersHandleRequestParams(
    projectId ?? ''
  );

  const allowedUserToHandle = CAN('write', abilityToHandle);

  useEffect(() => {
    if (!isAllRendered) {
      return;
    }

    if (hash !== `#invoiceHeaderId-${invoiceHeader.id}`) {
      return;
    }

    // run only once when scrolling, outerBarOpen state change caused unintentional dispatch
    if (outerBarOpen && !hasScrolled) {
      dispatch(
        setOuterBarState({
          type: 'invoice_header',
          contentId: invoiceHeader.id,
        })
      );
    }

    setShouldScrollTo(true);
  }, [
    setShouldScrollTo,
    dispatch,
    hash,
    invoiceHeader.id,
    outerBarOpen,
    isAllRendered,
    hasScrolled,
  ]);

  useEffect(() => {
    if (focus && scrollToRef.current) {
      // Move element into view when it is focused
      scrollToRef.current.focus();
    }
  }, [focus, scrollToRef]);

  const [
    selectedArrivalRows,
    setSelectedArrivalRows,
  ] = useArrivalRowSelection();

  const toggleIsOpen = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    setFocus(index);

    if (toggleExpandInvoice) {
      toggleExpandInvoice(invoiceHeader.id);
    }
  };

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const assignedArrivalRowsTotal = useSelector(
    getAssignedArrivalRowsTotal(invoiceHeader.id)
  );

  const toggleDropDown = (e: React.KeyboardEvent | React.MouseEvent) => {
    if (isClickOrKeyboardSelection(e)) {
      stopPropagation(e);
      e.preventDefault();
      setFocus(index);

      setDropDownVisibility(!dropDownVisible);

      if (onSelect) {
        onSelect(invoiceHeader.id);
      }
    }
  };

  const showOuterBar = (
    e: React.KeyboardEvent | React.MouseEvent | React.SyntheticEvent
  ) => {
    if (isClickOrKeyboardSelection(e)) {
      stopPropagation(e);
      setFocus(index);
      dispatch(
        setOuterBarState({
          type: 'invoice_header',
          contentId: invoiceHeader.id,
        })
      );

      if (onSelect) {
        onSelect(invoiceHeader.id);
      }
    }
  };

  const onChangeConvertInvoice = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (isMovable) {
        e.preventDefault();
        toggleDropDown(e);
        dispatch(convertInvoiceHeader(invoiceHeader.id));

        if (onSelect) {
          onSelect(invoiceHeader.id);
        }
      }
    }
  };

  const onDelegateInvoice = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (INVOICE_HEADER_STATUS_NOT_HANDLED.includes(invoiceHeader.statusId)) {
        e.preventDefault();
        toggleDropDown(e);
        setCurrentModal('DELEGATE_INVOICE_MODAL');
      }
    }
  };

  const onUploadAttachment = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      toggleDropDown(e);
      setCurrentModal('UPLOAD_INVOICE_ATTACHMENT_MODAL');
    }
  };

  const onOpenInNewTab = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      toggleDropDown(e);

      const imageUrl = `${apiBaseUrl}v1/attachments/purchase-invoice-headers/${invoiceHeader.id}`;
      window.open(imageUrl, '_blank');
    }
  };

  const onChangeAddManualEntry = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (INVOICE_HEADER_STATUS_NOT_HANDLED.includes(invoiceHeader.statusId)) {
        e.preventDefault();
        toggleDropDown(e);

        setShowManualEntryModal(true);
      }
    }
  };

  const onChangeMoveInvoice = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (isMovable) {
        e.preventDefault();
        toggleDropDown(e);
        setShowMoveModal(true);
      }
    }
  };

  const onChangeDeclineInvoice = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (unhandledOrComplaintPendingWithoutArrivalRows) {
        e.preventDefault();
        toggleDropDown(e);
        setShowDeclineModal(true);
      }
    }
  };

  const onStartInvoiceCorrection = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (
        INVOICE_HEADER_STATUS_CORRECTED.concat(
          INVOICE_HEADER_STATUS_ACCEPTED
        ).includes(invoiceHeader.statusId)
      ) {
        e.preventDefault();
        toggleDropDown(e);
        setInvoiceCorrectionModal(true);
      }
    }
  };

  const onChangeFileInvoiceComplaint = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (INVOICE_HEADER_STATUS_NOT_HANDLED.includes(invoiceHeader.statusId)) {
        e.preventDefault();
        toggleDropDown(e);
        setShowFileComplaintModal(true);
      }
    }
  };

  const onChangeCancelInvoiceComplaint = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      if (
        INVOICE_HEADER_STATUS_COMPLAINT_FILED.includes(invoiceHeader.statusId)
      ) {
        e.preventDefault();
        dispatch(cancelInvoiceComplaintHeader(invoiceHeader.id));
        toggleDropDown(e);
      }
    }
  };

  const arrivalRowIds = allSortedArrivalRowIds;

  const selectedArrivalRowIds = Object.keys(selectedArrivalRows).reduce(
    (prev, curr) => {
      if (selectedArrivalRows[curr]) {
        prev.push(curr);
      }

      return prev;
    },
    [] as string[]
  );

  const onClickCheckbox = () => {
    if (onSelect) {
      setSelectedArrivalRows(arrivalRowIds, !allArrivalRowsSelected);
    }
  };

  const allArrivalRowsSelected =
    selectedArrivalRowIds.length > 0
      ? arrivalRowIds.every((key) => selectedArrivalRowIds.includes(key))
      : false;

  const selectedArrivalRowIdsForThisInvoice = selectedArrivalRowIds.filter(
    (id) => arrivalRowIds.includes(id)
  );

  const status = getInvoiceHeaderStatus(invoiceHeader);
  const rowHighlighted = hash === `#invoiceHeaderId-${invoiceHeader.id}`;

  const hasSelectableArrivalRows = ALL_UNPROCESSED_INVOICE_STATUSES.includes(
    invoiceHeader.statusId
  );

  const openLinks = (e: React.SyntheticEvent) => {
    stopPropagation(e);

    attachmentUrls.forEach((url) => window.open(url, '_blank'));
  };

  const showArrivalRow = isOpen && arrivalRowIds.length > 0;
  const showEmptyArrivalRow = isOpen && arrivalRowIds.length <= 0;

  const addText = useTxt('order.receiveMode.invoice.add');
  const moveText = useTxt('order.receiveMode.cost.move');
  const delegateTxt = useTxt('order.receiveMode.invoice.delegate');
  const declineText = useTxt('order.receiveMode.invoice.decline');
  const fileReclaimText = useTxt('order.receiveMode.invoice.fileComplaint');
  const cancelReclaimText = useTxt('order.receiveMode.invoice.cancelComplaint');

  const startCorrectionText = useTxt(
    'order.receiveMode.invoice.startCorrection'
  );
  const manualEntryText = useTxt('order.receiveMode.invoice.manualEntry');
  const openText = useTxt('order.receiveMode.invoice.isOpen');
  const closedText = useTxt('order.receiveMode.invoice.isClosed');
  const amountInclVATText = useTxt('order.receiveMode.invoice.amountInclVAT');
  const noRowsAssignedText = useTxt('order.receiveMode.invoice.noArrivalRows');

  const checkBoxLabelText = useTxt('order.receiveMode.arrivalRowTH.chooseAll');

  const uploadAttachmentTxt = useTxt(
    'order.receiveMode.invoice.attachment.upload'
  );

  const openImageInNewTabTxt = useTxt(
    'order.receiveMode.invoice.image.inNewTab'
  );

  const statusText = useTxt(status.text, {
    identifier: status.identifier,
    handleVatAmountPending: status.handleVatAmountPending
      ? big.priceFormat(status.handleVatAmountPending)
      : '0',
    handleNetAmountPending: status.handleNetAmountPending
      ? big.priceFormat(status.handleNetAmountPending)
      : '0',
  });

  const shortStatusText = useTxt(status.shortText, {
    dateAndUser: status.dateAndUser ?? '',
  });

  const getClaimDropDownListItem = ({ statusId }: InvoiceHeader) =>
    INVOICE_HEADER_STATUS_COMPLAINT_FILED.includes(statusId) ? (
      <DropDownItem
        tabIndex={0}
        role="menuitem"
        disabled={!allowedUserToEdit}
        onClick={onChangeCancelInvoiceComplaint}
        onKeyDown={onChangeCancelInvoiceComplaint}
      >
        <IconPapertrace />
        {cancelReclaimText}
      </DropDownItem>
    ) : (
      <DropDownItem
        disabled={
          !INVOICE_HEADER_STATUS_NOT_HANDLED.includes(statusId) ||
          !allowedUserToEdit
        }
        tabIndex={0}
        role="menuitem"
        onClick={onChangeFileInvoiceComplaint}
        onKeyDown={onChangeFileInvoiceComplaint}
      >
        <IconPapertrace />
        {fileReclaimText}
      </DropDownItem>
    );

  const unhandledOrComplaintPendingWithoutArrivalRows =
    arrivalRowIds.length === 0 &&
    INVOICE_HEADER_STATUS_COMPLAINT_FILED.concat(
      INVOICE_HEADER_STATUS_NOT_HANDLED
    ).includes(invoiceHeader.statusId);

  const { isMovable } = invoiceHeader;

  const onSelectInvoiceRow = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      setFocus(index);
      e.preventDefault();
      toggleIsOpen(e);

      if (onSelect) {
        onSelect(invoiceHeader.id);
      }
    }
  };

  const linkPathName = generateUrl({
    route: routes.ORDER_WITH_INVOICE_FOCUSED,
    projectId: invoiceHeader.projectId ?? projectId,
    orderId: invoiceHeader.orderId,
    viewMode: 'receive',
    subViewMode: 'invoices',
    invoiceHeaderId: invoiceHeader.id,
  });

  const showCheckbox =
    onSelect && hasSelectableArrivalRows && arrivalRowIds.length > 0;

  const showVisibleCodeCell =
    invoiceHeader.orderId !== orderId && !outerBarOpen;

  return (
    <InvoiceTableBody isActive={isSelected}>
      <InvoiceModal
        projectId={projectId}
        modalType={currentModal}
        handleClose={() => setCurrentModal(undefined)}
        invoiceHeader={invoiceHeader}
      />

      {showMoveModal ? (
        <MoveCostModal
          projectId={projectId}
          onClose={() => setShowMoveModal(false)}
          cost={invoiceHeader}
        />
      ) : null}
      {showDeclineModal ? (
        <DeclineInvoiceModal
          invoiceHeader={invoiceHeader}
          onClose={() => setShowDeclineModal(false)}
        />
      ) : null}
      {showFileComplaintModal ? (
        <FileInvoiceComplaintModal
          invoiceHeader={invoiceHeader}
          onClose={() => setShowFileComplaintModal(false)}
        />
      ) : null}
      {showManualEntryModal ? (
        <ManualEntryModal
          invoiceHeader={invoiceHeader}
          onClose={() => setShowManualEntryModal(false)}
        />
      ) : null}
      {showInvoiceCorrectionModal ? (
        <CorrectionModal
          cost={invoiceHeader}
          onClose={() => setInvoiceCorrectionModal(false)}
        />
      ) : null}
      <InvoiceRow
        selected={isSelected}
        isHandled={isHandled}
        rowHighlighted={rowHighlighted}
        ref={scrollToRef}
        clickable
        tabIndex={focus ? 0 : -1}
        data-testid={`invoice-header-selector-${invoiceHeader.id}`}
        onClick={onSelectInvoiceRow}
        onKeyDown={onSelectInvoiceRow}
      >
        <SelectableInvoiceCell selected={isSelected}>
          <IconButton
            icon={isOpen ? IconDown : IconRight}
            aria-label={isOpen ? openText : closedText}
          />
        </SelectableInvoiceCell>
        <CompactCell colSpan={2} contentContainer={NoWrapCellDiv}>
          {showCheckbox ? (
            <StyledCheckboxDiv>
              <Checkbox
                checked={allArrivalRowsSelected || false}
                onChange={onClickCheckbox}
                disabled={!allowedUserToEdit}
                aria-label={checkBoxLabelText}
              />
            </StyledCheckboxDiv>
          ) : null}
          <DescriptionSpan>{invoiceHeader.vendorInvoiceNo}</DescriptionSpan>
          {invoiceHeader.invoiceNumber &&
          invoiceHeader.invoiceNumber.length > 0 ? (
            <SimpleTextToolTip>{invoiceHeader.invoiceNumber}</SimpleTextToolTip>
          ) : null}
        </CompactCell>
        <CompactCell
          align={outerBarOpen ? 'center' : undefined}
          contentContainer={NoWrapCellDiv}
          colSpan={showVisibleCodeCell || outerBarOpen ? 1 : 2}
        >
          <IndicatorImg
            isCompact={outerBarOpen}
            alt={statusText}
            src={status.icon}
          />
          {outerBarOpen ? null : (
            <DescriptionSpan>{shortStatusText}</DescriptionSpan>
          )}
          {invoiceHeader.comment && invoiceHeader.comment.length > 0 ? (
            <SimpleTextToolTip>{invoiceHeader.comment}</SimpleTextToolTip>
          ) : null}
          {isTasks ? (
            <IconContainer>
              <IconDelegate />
            </IconContainer>
          ) : null}
        </CompactCell>
        {showVisibleCodeCell ? (
          <CompactCell>
            <NoOverflowSpan>
              <Link
                data-testid={`navigate-to-order-${invoiceHeader.orderId}-invoice-${invoiceHeader.id}`}
                to={linkPathName}
              >
                {invoiceHeaderOrderVisibleCode}
              </Link>
            </NoOverflowSpan>
          </CompactCell>
        ) : null}
        <CompactCell>
          <NoOverflowSpan>
            {outerBarOpen && invoiceHeader.orderId !== orderId
              ? `${invoiceHeaderOrderVisibleCode} ${invoiceHeader.supplierName}`
              : invoiceHeader.supplierName}
          </NoOverflowSpan>
        </CompactCell>
        <CompactCell align="right">
          <InvoiceSumWrapper>
            {big.priceFormat(invoiceHeader.amount)}
          </InvoiceSumWrapper>
          <SimpleTextToolTip>
            {amountInclVATText}{' '}
            {big.priceFormat(invoiceHeader.amountIncludingVat)}
          </SimpleTextToolTip>
        </CompactCell>
        <CompactCell align="right">
          {dateFormat.format(new Date(invoiceHeader.dueDate))}
        </CompactCell>
        {!outerBarOpen ? (
          <UnitPriceCompactCell align="right" colSpan={5}>
            {assignedArrivalRowsTotal}
          </UnitPriceCompactCell>
        ) : (
          <CompactCell align="right">{assignedArrivalRowsTotal}</CompactCell>
        )}
        <CompactCell colSpan={outerBarOpen ? 2 : 3} />
        <RightPaddedCompactCell>
          <StyledIconButton
            icon={IconKebabMenu}
            onClick={toggleDropDown}
            onKeyDown={toggleDropDown}
            data-testid={`kebab-menu-${invoiceHeader.id}`}
            ref={buttonRef}
          />
          <StyledIconButton
            icon={IconSplitScreen}
            onClick={showOuterBar}
            onKeyDown={showOuterBar}
            data-testid={`split-screen-${invoiceHeader.id}`}
          />
          {dropDownVisible ? (
            <DropDownList
              role="menu"
              onClose={() => setDropDownVisibility(false)}
              parentRef={buttonRef}
            >
              {!isHandled ? (
                <DropDownItem
                  disabled={
                    !INVOICE_HEADER_STATUS_NOT_HANDLED.includes(
                      invoiceHeader.statusId
                    ) || !allowedUserToEdit
                  }
                  tabIndex={0}
                  role="menuitem"
                  onClick={onDelegateInvoice}
                  onKeyDown={onDelegateInvoice}
                >
                  <IconDelegate />
                  {delegateTxt}
                </DropDownItem>
              ) : null}
              <DropDownItem
                disabled={!isMovable || !allowedUserToEdit}
                tabIndex={0}
                role="menuitem"
                onClick={onChangeConvertInvoice}
                onKeyDown={onChangeConvertInvoice}
              >
                <IconPaperAdd />
                {addText}
              </DropDownItem>
              <DropDownItem
                disabled={!isMovable || !allowedUserToEdit}
                tabIndex={0}
                role="menuitem"
                onClick={onChangeMoveInvoice}
                onKeyDown={onChangeMoveInvoice}
              >
                <IconChange />
                {moveText}
              </DropDownItem>
              <DropDownItem
                disabled={
                  !INVOICE_HEADER_STATUS_NOT_HANDLED.includes(
                    invoiceHeader.statusId
                  ) || !allowedUserToEdit
                }
                tabIndex={0}
                role="menuitem"
                onClick={onChangeAddManualEntry}
                onKeyDown={onChangeAddManualEntry}
              >
                <IconPaperEdit />

                {manualEntryText}
              </DropDownItem>
              <DropDownItem
                disabled={
                  !unhandledOrComplaintPendingWithoutArrivalRows ||
                  !allowedUserToHandle
                }
                tabIndex={0}
                role="menuitem"
                onClick={onChangeDeclineInvoice}
                onKeyDown={onChangeDeclineInvoice}
              >
                <IconDecline />
                {declineText}
              </DropDownItem>
              {getClaimDropDownListItem(invoiceHeader)}
              <DropDownItem
                tabIndex={0}
                role="menuitem"
                onClick={onUploadAttachment}
                onKeyDown={onUploadAttachment}
              >
                <IconAttachmentBlack />
                {uploadAttachmentTxt}
              </DropDownItem>
              <DropDownItem
                tabIndex={0}
                role="menuitem"
                onClick={onOpenInNewTab}
                onKeyDown={onOpenInNewTab}
              >
                <IconNewTab />
                {openImageInNewTabTxt}
              </DropDownItem>
              {isHandled &&
              INVOICE_HEADER_STATUS_CORRECTED.concat(
                INVOICE_HEADER_STATUS_ACCEPTED
              ).includes(invoiceHeader.statusId) ? (
                <DropDownItem
                  tabIndex={0}
                  role="menuitem"
                  disabled={!allowedUserToEdit}
                  onClick={onStartInvoiceCorrection}
                  onKeyDown={onStartInvoiceCorrection}
                >
                  <IconPaperFix />
                  {startCorrectionText}
                </DropDownItem>
              ) : null}
            </DropDownList>
          ) : null}
          &nbsp;
          {attachmentUrls.length > 0 ? (
            <StyledIconButton icon={IconAttachmentBlack} onClick={openLinks}>
              {attachmentUrls.length > 1 ? (
                <AttachmentCount>{attachmentUrls.length}</AttachmentCount>
              ) : null}
            </StyledIconButton>
          ) : null}
        </RightPaddedCompactCell>
      </InvoiceRow>
      {showArrivalRow ? (
        <>
          {sortedArrivalRowIdsForThisOrder.map((id) => (
            <ArrivalRow
              projectId={projectId}
              key={`invoice-${invoiceHeader.id}-arrival-row-${id}`}
              arrivalRowId={id}
              isSelectable={hasSelectableArrivalRows}
              isHandled={
                INVOICE_HEADER_STATUS_ACCEPTED.includes(
                  invoiceHeader.statusId
                ) ||
                INVOICE_HEADER_STATUS_CORRECTED.includes(invoiceHeader.statusId)
              }
            />
          ))}
          {Object.keys(sortedArrivalRowIdsForOtherOrders).map(
            (groupedOrderId, indexNo) => {
              const arrivalRowsForOrder =
                sortedArrivalRowIdsForOtherOrders[groupedOrderId];

              return (
                <React.Fragment
                  key={`invoice-${invoiceHeader.id}-arrival-row-order-group-${groupedOrderId}`}
                >
                  <tr
                    data-testid={`invoice-${invoiceHeader.id}-arrival-row-order-group-${groupedOrderId}`}
                  >
                    <ProcessingCell
                      colSpan={outerBarOpen ? 11 : 17}
                      isActive={isSelected}
                    >
                      <ArrivalRowOrderGroup
                        orderId={groupedOrderId}
                        invoiceHeader={invoiceHeader}
                        first={indexNo === 0}
                      />
                    </ProcessingCell>
                  </tr>
                  {arrivalRowsForOrder?.map((arrivalRow) => (
                    <ArrivalRow
                      projectId={projectId}
                      key={`invoice-${invoiceHeader.id}-arrival-row-${arrivalRow.id}`}
                      arrivalRowId={arrivalRow.id}
                      isSelectable={hasSelectableArrivalRows}
                      isHandled={
                        INVOICE_HEADER_STATUS_ACCEPTED.includes(
                          invoiceHeader.statusId
                        ) ||
                        INVOICE_HEADER_STATUS_CORRECTED.includes(
                          invoiceHeader.statusId
                        )
                      }
                      crossOrder
                    />
                  ))}
                </React.Fragment>
              );
            }
          )}
        </>
      ) : null}
      {showEmptyArrivalRow ? (
        <StyledArrivalRow>
          <LeftPaddedCompactCell colSpan={outerBarOpen ? 9 : 14}>
            {noRowsAssignedText}
          </LeftPaddedCompactCell>
        </StyledArrivalRow>
      ) : null}
      {isOpen && isTasks ? (
        <InvoiceDelegationRow
          invoiceHeaderId={invoiceHeader.id}
          currentUserId={currentUser?.id ?? ''}
          onShowOuterBar={showOuterBar}
          outerBarOpen={outerBarOpen}
        />
      ) : null}

      {isOpen && !isHandled ? (
        <tr>
          <ProcessingCell
            colSpan={outerBarOpen ? 11 : 17}
            isActive={isSelected}
          >
            <ProcessingTool
              invoice={invoiceHeader}
              arrivalRowIds={arrivalRowIds}
              selectedRelatedArrivalRowIds={selectedArrivalRowIdsForThisInvoice}
            />
          </ProcessingCell>
        </tr>
      ) : null}
      {/* gives gap between two invoice rows */}
      <TableBodyGap isActive={isSelected}>
        <td colSpan={outerBarOpen ? 11 : 17} />
      </TableBodyGap>
    </InvoiceTableBody>
  );
};

const InvoiceSumWrapper = styled.span`
  padding-right: 1rem;
  cursor: text;
  overflow: hidden;
`;

const IconContainer = styled.span`
  padding-left: 0.25rem;
`;

export const SimpleTextToolTip = styled(SimpleTooltip)`
  top: 0;
  width: auto;
  cursor: text;
  transform: translateY(-100%);
`;

export const StyledIconButton = styled(IconButton)`
  position: relative;
  margin-right: ${(props) => props.theme.margin[8]};
`;

export const StyledCheckboxDiv = styled.div`
  margin-right: ${(props) => props.theme.margin[8]};
`;

export const NoWrapCellDiv = styled(CellDiv)`
  display: flex;
  flex-flow: row nowrap;
`;

export const DescriptionSpan = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default React.memo(Invoice);
