import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik, FormikErrors } from 'formik';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import { getCalendar } from '../../../store/reducers/schedule/nextgenScheduleData';
import { getWorkPackageCodes } from '../../../store/reducers/workPackage';

import {
  updateWorkSection,
  getWorkPackageTimelineForProject,
  getWorkPackageGroupTimelineForProject,
  getProjectTimelineForProject,
} from '../../../store/actions';
import { fetchNextgenScheduleDataForProject } from '../../../store/actions/schedule/nextgenScheduleData';

import { APIWorkPackage, APIWorkPackagePutBody } from '../../../types/api';

import useRemoteData from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import {
  CenteredButtonGroup,
  SecondaryButton,
  PrimaryButton,
  ToggleButton,
} from '../../../components/Buttons';
import {
  DatePicker,
  DayPickerContainer,
} from '../../../components/DatePickerInput';
import TextInput from '../../../components/Input/TextInput';
import { Spinner } from '../../../components/Loading';
import Txt from '../../../components/Txt';

import { uniqueCode } from '../../../utils/decoders';
import { getEOMonth, MAX_YEARS_FROM_NOW } from '../../../utils/general';

import { IconCheckmark } from '../../../assets/svg';

import { routes, useParams } from '../../../routes';

type EditWPProps = {
  workPackage: APIWorkPackage;
  onClose: () => void;
  isScheduleInUse: boolean;
  setScheduleInUse: React.Dispatch<React.SetStateAction<boolean>>;
  scheduleTreeSelection: {
    workSectionClassId?: string | null | undefined;
    workPackageVirtualSpaceLinkages?: {
      nextgenWorkPackageId: string | null;
      virtualSpaceId: string | null;
    }[];
  };
  setScheduleTreeSelection: React.Dispatch<
    React.SetStateAction<{
      workSectionClassId?: string | null | undefined;
      workPackageVirtualSpaceLinkages?: {
        nextgenWorkPackageId: string | null;
        virtualSpaceId: string | null;
      }[];
    }>
  >;
};

const EditWorkPackageForm = ({
  workPackage,
  onClose,
  isScheduleInUse,
  setScheduleInUse,
  scheduleTreeSelection,
  setScheduleTreeSelection,
}: EditWPProps) => {
  const [pocValue, setPoCValue] = React.useState<number>(0);

  const workPackageId = workPackage.id;

  const isRequired = useTxt('form.inputError.isRequired');
  const codeAlreadyExists = useTxt('form.inputError.codeAlreadyExists');
  const maxLength = useTxt('form.inputError.maxLength');
  const startDateError = useTxt('form.inputError.startDateError');
  const endDateError = useTxt('form.inputError.endDateError');

  const maxDateError = useTxt('form.inputError.maxDateError', {
    yearsFromNow: MAX_YEARS_FROM_NOW,
  });

  const { projectId } = useParams(routes.WORKSECTION_EXPANDED);

  const dispatch = useDispatch();

  const workPackageCodes = useSelector(
    getWorkPackageCodes(workPackage?.workPackageGroupId || '')
  );

  const calendarEntries =
    useRemoteData(
      getCalendar(projectId),
      fetchNextgenScheduleDataForProject({ projectId })
    ) ?? [];

  const workingDaysAsStrings = calendarEntries.map(
    (entry) => new Date(entry.planned_end).toISOString().split('T')[0]
  );

  const distinctWorkingDays = [...new Set(workingDaysAsStrings)].map((day) =>
    new Date(day).getTime()
  );

  type FormValues = {
    name: string;
    code: string;
    startDate?: string;
    endDate?: string;
    workSectionClassId?: string | null;
    workPackageVirtualSpaceLinkages?: {
      nextgenWorkPackageId: string | null;
      virtualSpaceId: string | null;
    }[];
  };

  const onEditSuccess = () => {
    dispatch(getWorkPackageTimelineForProject({ projectId }));
    dispatch(getWorkPackageGroupTimelineForProject({ projectId }));
    dispatch(getProjectTimelineForProject({ projectId }));
    onClose();
  };

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (values.name === '') {
      errors.name = isRequired;
    }

    if (values.code === '') {
      errors.code = isRequired;
    }

    if (
      !uniqueCode(workPackageCodes).is(values.code) &&
      values.code !== workPackage?.code
    ) {
      errors.code = codeAlreadyExists;
    }

    if (
      (values.workPackageVirtualSpaceLinkages ?? []).length === 0 &&
      !values.workSectionClassId
    ) {
      if (values.startDate === '') {
        errors.startDate = isRequired;
      }

      if (values.endDate === '') {
        errors.endDate = isRequired;
      }

      if (values.endDate && values.startDate) {
        if (values.endDate < values.startDate) {
          errors.endDate = endDateError;
          errors.startDate = startDateError;
        }

        const today = new Date();

        const maxYearsFromNow = new Date(
          today.setFullYear(today.getFullYear() + MAX_YEARS_FROM_NOW)
        );
        const endDateAsDate = new Date(values.endDate);

        if (endDateAsDate > maxYearsFromNow) {
          errors.endDate = maxDateError;
        }
      }
    }

    if (values.name.length > 200) {
      errors.name = maxLength;
    }

    return errors;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      name: workPackage?.name ? workPackage.name : '',
      code: workPackage?.code ? workPackage?.code : '',
      startDate: workPackage?.startDate ? workPackage.startDate : '',
      endDate: workPackage?.endDate ? workPackage.endDate : '',
      workSectionClassId: workPackage?.workSectionClassId
        ? workPackage?.workSectionClassId
        : null,
      workPackageVirtualSpaceLinkages: workPackage?.workPackageVirtualSpaceLinkages
        ? workPackage?.workPackageVirtualSpaceLinkages
        : [],
    },
    validate,
    onSubmit: (values) => {
      const body: APIWorkPackagePutBody = {
        name: values.name,
        code: values.code,
        startDate:
          values.startDate && !isScheduleInUse
            ? new Date(values.startDate)
            : undefined,
        endDate:
          values.endDate && !isScheduleInUse
            ? new Date(values.endDate)
            : undefined,
        updatedAt: new Date().toISOString(),
        workSectionClassId: values.workSectionClassId ?? null,
        ...(!isScheduleInUse
          ? { workPackageVirtualSpaceLinkages: null }
          : {
              workPackageVirtualSpaceLinkages:
                values.workPackageVirtualSpaceLinkages ?? [],
            }),
      };

      dispatch(updateWorkSection(workPackageId, body, onEditSuccess));
    },
  });

  const memoizedDates = React.useMemo(() => {
    return {
      startDate: new Date(formik.values.startDate ?? '').getTime(),
      endDate: new Date(formik.values.endDate ?? '').getTime(),
    };
  }, [formik.values.startDate, formik.values.endDate]);

  const convertRawPoC = (rawPoC: number) => {
    if (rawPoC > 1) {
      return 1;
    }

    if (rawPoC < 0) {
      return 0;
    }

    return rawPoC;
  };

  useEffect(() => {
    if (
      !Number.isNaN(memoizedDates.startDate) &&
      !Number.isNaN(memoizedDates.endDate)
    ) {
      const diff = distinctWorkingDays.filter(
        (row) => row < memoizedDates.endDate && row > memoizedDates.startDate
      ).length;

      const diffFromNow = distinctWorkingDays.filter(
        (row) =>
          row < getEOMonth(new Date()).getTime() &&
          row > memoizedDates.startDate
      ).length;

      const rawPoC = diffFromNow / diff;
      const poc = convertRawPoC(rawPoC);
      setPoCValue(poc * 100);
    }
  }, [
    memoizedDates.startDate,
    memoizedDates.endDate,
    setPoCValue,
    distinctWorkingDays,
  ]);

  // setting date pickers and work section class id / package linkages
  const { setFieldValue } = formik;

  const memoizedTreeSelection = React.useMemo(() => scheduleTreeSelection, [
    scheduleTreeSelection,
  ]);

  const memoizedFormikValues = React.useMemo(() => formik.values, [
    formik.values,
  ]);

  useEffect(() => {
    if (
      memoizedTreeSelection.workSectionClassId !==
      memoizedFormikValues.workSectionClassId
    ) {
      setFieldValue(
        'workSectionClassId',
        memoizedTreeSelection.workSectionClassId
      );
    }
  }, [
    memoizedTreeSelection.workSectionClassId,
    setFieldValue,
    memoizedFormikValues.workSectionClassId,
  ]);

  const callbackSetFieldValue = React.useCallback(
    (
      selection:
        | {
            nextgenWorkPackageId: string | null;
            virtualSpaceId: string | null;
          }[]
        | undefined
    ) => {
      setFieldValue('workPackageVirtualSpaceLinkages', selection);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (
      !isEqual(
        memoizedTreeSelection.workPackageVirtualSpaceLinkages,
        memoizedFormikValues.workPackageVirtualSpaceLinkages
      )
    ) {
      callbackSetFieldValue(
        memoizedTreeSelection.workPackageVirtualSpaceLinkages
      );
    }
  }, [
    memoizedTreeSelection.workPackageVirtualSpaceLinkages,
    callbackSetFieldValue,
    memoizedFormikValues.workPackageVirtualSpaceLinkages,
  ]);

  const workpackageCode = useTxt('worksection.workpackage.code');
  const workpackageName = useTxt('worksection.workpackage.name');
  const startDate = useTxt('worksection.workpackage.startDate');
  const endDate = useTxt('worksection.workpackage.endDate');

  const toggleButtonIcon = (showCheckMark: boolean) => {
    return showCheckMark ? IconCheckmark : () => <></>;
  };

  const setScheduleOutOfUseButtonClick = () => {
    setScheduleTreeSelection({
      workSectionClassId: null,
      workPackageVirtualSpaceLinkages: undefined,
    });

    setScheduleInUse(false);
  };

  const setScheduleInUseButtonClick = () => {
    setScheduleTreeSelection({
      workSectionClassId: workPackage.workSectionClassId,
      workPackageVirtualSpaceLinkages:
        workPackage.workPackageVirtualSpaceLinkages,
    });

    setScheduleInUse(true);
  };

  return (
    <StyledForm name="edit-wp-nextgen-form" onSubmit={formik.handleSubmit}>
      <FormColumnDiv>
        <FormNameAndCodeRowDiv>
          <TextInput
            label={workpackageCode}
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
            errorMessage={formik.errors.code}
            alternateLabel
          />
          <StyledInputDiv>
            <TextInput
              label={workpackageName}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              errorMessage={formik.errors.name}
              alternateLabel
            />
          </StyledInputDiv>
        </FormNameAndCodeRowDiv>
        <StyledScheduleInUseButtonGroup>
          <StyledMonitoringSpan>
            <Txt id="worksection.workpackage.monitoring" />
          </StyledMonitoringSpan>

          <ToggleButton
            side="left"
            type="button"
            icon={toggleButtonIcon(!isScheduleInUse)}
            selected={!isScheduleInUse}
            onClick={setScheduleOutOfUseButtonClick}
          >
            <StyledButtonText>
              <Txt id="worksection.workpackage.monitoring.timespan" />
            </StyledButtonText>
          </ToggleButton>
          <ToggleButton
            side="right"
            type="button"
            icon={toggleButtonIcon(isScheduleInUse)}
            selected={isScheduleInUse}
            onClick={setScheduleInUseButtonClick}
          >
            <StyledButtonText>
              <Txt id="worksection.workpackage.monitoring.linkedSchedule" />
            </StyledButtonText>
          </ToggleButton>
          <StyledHr />
        </StyledScheduleInUseButtonGroup>
        {!isScheduleInUse ? (
          <StyledColumnDiv>
            <StyledInfoSpan>
              <Txt id="worksection.workpackage.timespan.explanation" />
            </StyledInfoSpan>
            <StyledDatePickers>
              <DatePicker
                name="startDate"
                date={new Date(formik.values.startDate ?? '')}
                label={startDate}
                onDayChange={(val) => setFieldValue('startDate', val)}
                errorMessage={formik.errors.startDate}
                alternateLabel
                contentContainer={CustomDatePickerContainer}
              />
              <StyledDash>-</StyledDash>
              <DatePicker
                name="endDate"
                date={new Date(formik.values.endDate ?? '')}
                label={endDate}
                onDayChange={(val) => setFieldValue('endDate', val)}
                errorMessage={formik.errors.endDate}
                alternateLabel
                contentContainer={CustomDatePickerContainer}
              />
            </StyledDatePickers>
            <StyledPoCContainer>
              <Txt id="worksection.workpackage.pocEOMonth" />
              <StyledPocValue>{`${pocValue.toFixed(0)}%`}</StyledPocValue>
            </StyledPoCContainer>
          </StyledColumnDiv>
        ) : null}
      </FormColumnDiv>

      <StyledButtonGroup>
        <SecondaryButton type="button" onClick={onClose}>
          <Txt id="common.cancel" />
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={
            !formik.isValid ||
            formik.isSubmitting ||
            (!uniqueCode(workPackageCodes).is(formik.values.code) &&
              formik.values.code !== workPackage?.code)
          }
        >
          {formik.isSubmitting ? (
            <Spinner size="1rem" light />
          ) : (
            <Txt id="common.save" />
          )}
        </PrimaryButton>
      </StyledButtonGroup>
    </StyledForm>
  );
};

export default EditWorkPackageForm;

const StyledForm = styled.form`
  height: 10rem;
`;

const FormNameAndCodeRowDiv = styled.div`
  padding-top: 0.5rem;
  display: flex;
  gap: 1rem;

  /* stylelint-disable selector-max-type -- todo easier to read */

  input[name='name'] {
    min-width: 20rem;
  }

  input[name='code'] {
    min-width: 5rem;
  }

  label {
    white-space: nowrap;
  }
`;

const FormColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  transition: width 1s;
`;

const StyledDatePickers = styled.div`
  position: relative;

  margin-top: ${({ theme }) => theme.margin[24]};

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 1rem;

  input {
    border: 1px solid ${(props) => props.theme.color.inputBorder};
    border-radius: ${(props) => props.theme.margin[4]};
    padding: ${(props) => props.theme.margin[10]};
  }
`;

const StyledScheduleInUseButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  overflow: hidden;
`;

const StyledButtonGroup = styled(CenteredButtonGroup)`
  position: absolute;
  left: 0;
  bottom: 0;

  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.15);

  padding: ${(props) => props.theme.margin[18]};

  width: 100%;

  justify-content: right;
`;

const StyledInputDiv = styled.div`
  flex-grow: 1;
`;

const StyledMonitoringSpan = styled.span`
  margin: 0 ${(props) => props.theme.margin[16]} 0 0;

  min-width: 9rem;

  display: flex;
  align-items: center;

  font-weight: 700;
`;

const StyledDash = styled.div`
  padding-bottom: 1.5rem;
  align-self: center;
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSize.h1};
`;

const StyledColumnDiv = styled.div`
  margin-top: ${(props) => props.theme.margin[24]};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledButtonText = styled.span`
  margin-left: ${(props) => props.theme.margin[4]};
`;

export const StyledPoCContainer = styled.div`
  margin: ${(props) => props.theme.margin[24]} 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledPocValue = styled.span`
  margin-left: ${(props) => props.theme.margin[8]};

  border-radius: 1.5rem;

  padding: ${(props) => props.theme.margin[4]};

  min-width: 3rem;

  background: ${(props) =>
    props.theme.color['M3/sys/light/secondary-container']};

  text-align: center;
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSize.base};
`;

const CustomDatePickerContainer: React.FC<{
  invalid: boolean;
  alternateLabel?: boolean;
}> = ({ invalid, alternateLabel, children }) => {
  return (
    <DayPickerContainer
      invalid={invalid}
      alternateLabel={alternateLabel}
      paddingBottom="0.5rem"
    >
      {children}
    </DayPickerContainer>
  );
};

const StyledHr = styled.hr`
  margin-left: ${(props) => props.theme.margin[16]};
  flex-grow: 1;
`;

export const StyledInfoSpan = styled.span`
  color: ${(props) => props.theme.color['M3/sys/light/on-surface-variant']};
`;
