import { Reducer } from 'redux';

import { APIProjectUser, APIUser } from '../../types/api';

import { BackendError } from '../../utils/api';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';

import { AppState } from '.';

export type NewUserRequestState = Partial<
  Record<
    string,
    remoteData.RemoteData<APIProjectUser, BackendError | undefined>
  >
>;

export type UserState = {
  loggedIn?: boolean;
  current: APIUser | undefined;
  error?: BackendError | undefined;
  newUserRequests?: NewUserRequestState;
};

const initialState: UserState = {
  loggedIn: undefined,
  current: undefined,
};

const userReducer: Reducer<UserState, ActionTypes> = (
  state = initialState,
  action
): UserState => {
  switch (action.type) {
    case 'GET_USER_FAILURE':
      return {
        ...state,
        loggedIn: false,
        current: undefined,
        error: action.payload.error,
      };
    case 'GET_USER_SUCCESS':
      return {
        ...state,
        loggedIn: true,
        current: action.payload,
        error: undefined,
      };
    case 'PUT_USER_SUCCESS': {
      return { ...state, current: action.payload, error: undefined };
    }

    default:
      return state;
  }
};

export const selectCurrentUser = (state: AppState) => state.user.current;

export const getUserName = (state: AppState) => {
  const name = state.user.current?.name ?? '';

  return name;
};

export default userReducer;
