import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { orderBy } from 'lodash';
import styled from 'styled-components';

import { getNotificationFeed } from '../../../store/reducers/ui';

import { NotificationFeedItem } from '../../../types/general';

import useTxt from '../../../hooks/useTxt';

import { checkDueDate } from '../../../utils/general';

import {
  IconMenuIcon,
  IconClaimedInvoices,
  IconPerson,
  IconInvoiceUnprocessed,
  IconExternalChanges,
  IconChatLight,
  IconInvalid,
  IconInvalidLight,
} from '../../../assets';

import NotificationIndicator from '../../NotificationIndicator';
import Sidebar from '../../Sidebar/Sidebar';
import Tooltip from '../../Tooltip';
import Txt from '../../Txt';
import {
  FeedSidebarHeader,
  Filters,
  Filter,
  NotificationsList,
  NotificationsListHeader,
} from './Components';
import Notification from './Notification';

type FeedSidebarProps = {
  closeSidebar: () => void;
};
type FilterType =
  | 'newest'
  | 'all'
  | 'unread'
  | 'eventsForHandledInvoices'
  | 'unprocessedInvoices'
  | 'notificationMessages'
  | 'delegationNotification'
  | 'externalChangeNotifications';

const Container = styled.div`
  padding: ${(props) => `${props.theme.margin[24]} ${props.theme.margin[32]}`};
`;

const notificationTypesForHandledInvoices = ['3', '5', '9', '10', '11'];

const FeedSidebar = ({ closeSidebar }: FeedSidebarProps) => {
  const [filter, setFilter] = useState<FilterType>('all');

  const notifications = useSelector(getNotificationFeed);

  const unprocessedNotifications = notifications.filter(
    (n) => n.notificationType === '1' || n.notificationType === '2'
  );

  const processedNotifications = notifications.filter((n) =>
    notificationTypesForHandledInvoices.includes(n.notificationType)
  );

  const messageNotifications = notifications.filter(
    (n) => n.notificationType === '4' && !n.isRead
  );

  const delegationNotification = notifications.filter(
    (n) =>
      (n.notificationType === '6' || n.notificationType === '7') && !n.isRead
  );

  const externalChangeNotifications = notifications.filter(
    (n) => n.notificationType === '8'
  );

  const filteredNotifications = notifications.filter(
    (row: NotificationFeedItem) => {
      switch (filter) {
        case 'unread':
          return !row.isRead;
        case 'unprocessedInvoices':
          return row.notificationType === '2' || row.notificationType === '1';
        case 'eventsForHandledInvoices': {
          return notificationTypesForHandledInvoices.includes(
            row.notificationType
          );
        }
        case 'notificationMessages': {
          return row.notificationType === '4';
        }
        case 'delegationNotification': {
          return row.notificationType === '6' || row.notificationType === '7';
        }
        case 'externalChangeNotifications': {
          return row.notificationType === '8';
        }
        default: {
          return true;
        }
      }
    }
  );

  const sortedNotifications =
    filter === 'unprocessedInvoices'
      ? orderBy(filteredNotifications, ['firstDueDate', 'id'], ['asc', 'desc'])
      : orderBy(filteredNotifications, ['createdAt', 'id'], ['desc', 'desc']);

  const notificationHeader = useTxt('navigation.notifications');
  const noNotifications = useTxt('navigation.notifications.noNotifications');

  const unreadCount =
    notifications.filter((n) => !(n.notificationType === '4' && n.isRead))
      .length || '';

  type NotificationDictionary = {
    name: string;
    tooltip?: string;
    filter: FilterType;
    icon: string;
    notifications: NotificationFeedItem[];
    hasOverDues?: boolean;
    hasAlmostOverDues?: boolean;
  };

  const hasOverdueInvoice = (notif: NotificationFeedItem[]) =>
    notif.some((n) =>
      n.purchaseInvoiceHeaders.some(
        (invoice) => checkDueDate(invoice.dueDate) < 0
      )
    );

  const hasAlmostOverdueInvoice = (notif: NotificationFeedItem[]) =>
    notif.some((notification) =>
      notification.purchaseInvoiceHeaders.some((invoice) => {
        const daysUntilDue = checkDueDate(invoice.dueDate);

        return daysUntilDue <= 7 && daysUntilDue > 0;
      })
    );

  const notificationDictionary: Record<string, NotificationDictionary> = {
    '1': {
      name: useTxt('navigation.notifications.eventsForHandledInvoices'),
      tooltip: useTxt(
        'navigation.notifications.eventsForHandledInvoices.toolTip'
      ),
      filter: 'eventsForHandledInvoices',
      icon: IconClaimedInvoices,
      notifications: processedNotifications,
      hasOverDues: false,
      hasAlmostOverDues: false,
    },
    '2': {
      name: useTxt('navigation.notifications.unprocessedInvoices'),
      tooltip: useTxt('navigation.notifications.unprocessedInvoices.toolTip'),
      filter: 'unprocessedInvoices',
      icon: IconInvoiceUnprocessed,
      notifications: unprocessedNotifications,
      hasOverDues: hasOverdueInvoice(unprocessedNotifications),
      hasAlmostOverDues: hasAlmostOverdueInvoice(unprocessedNotifications),
    },
    '3': {
      name: useTxt('order.receiveMode.invoice.delegate.reviewRequestFromMe'),
      tooltip: useTxt('navigation.notifications.delegation.toolTip'),
      filter: 'delegationNotification',
      icon: IconPerson,
      notifications: delegationNotification,
      hasOverDues: hasOverdueInvoice(delegationNotification),
      hasAlmostOverDues: hasAlmostOverdueInvoice(delegationNotification),
    },
    '4': {
      name: useTxt('navigation.notifications.messages'),
      filter: 'notificationMessages',
      icon: IconChatLight,
      notifications: messageNotifications,
      hasOverDues: hasOverdueInvoice(messageNotifications),
      hasAlmostOverDues: hasAlmostOverdueInvoice(messageNotifications),
    },
    '5': {
      name: useTxt('navigation.notifications.externalChangeNotifications'),
      filter: 'externalChangeNotifications',
      icon: IconExternalChanges,
      notifications: externalChangeNotifications,
      hasOverDues: hasOverdueInvoice(externalChangeNotifications),
      hasAlmostOverDues: hasAlmostOverdueInvoice(externalChangeNotifications),
    },
  };

  return (
    <Sidebar align="left" onClose={closeSidebar} isDarkBackground>
      <Container>
        <FeedSidebarHeader>
          <StyledHeader>{notificationHeader}</StyledHeader>
          <Filters>
            <StyledFilter
              onClick={() => setFilter('all')}
              className={filter === 'all' ? 'selected' : ''}
            >
              <img src={IconMenuIcon} alt="menu icon" />
              {useTxt('navigation.notifications.all')}
              {unreadCount ? (
                <DueInvoicesCount>
                  <NotificationIndicator
                    notificationCount={unreadCount}
                    dimentions={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </DueInvoicesCount>
              ) : null}
            </StyledFilter>
            {Object.keys(notificationDictionary).map((key) => {
              const { hasOverDues, hasAlmostOverDues } = notificationDictionary[
                key
              ];

              return (
                <Tooltip
                  tip={notificationDictionary[key].tooltip ?? ''}
                  className="hoverable-tooltip"
                  place="top"
                  delayShow={500}
                  key={key}
                >
                  <StyledFilter
                    onClick={() =>
                      setFilter(notificationDictionary[key].filter)
                    }
                    className={
                      filter === notificationDictionary[key].filter
                        ? 'selected'
                        : ''
                    }
                  >
                    <img
                      src={notificationDictionary[key].icon}
                      alt="icon claimed invoice"
                    />
                    {notificationDictionary[key].name}
                    {/* due soon indicators */}
                    {notificationDictionary[key].notifications.length > 0 ? (
                      <DueInvoicesCount>
                        <NotificationIndicator
                          notificationCount={
                            notificationDictionary[key].notifications.length
                          }
                          dimentions={{
                            width: 20,
                            height: 20,
                          }}
                        />
                        {hasOverDues ? (
                          <img
                            src={IconInvalid}
                            alt="invalid"
                            width={19}
                            height={19}
                          />
                        ) : null}

                        {!hasOverDues && hasAlmostOverDues ? (
                          <img
                            src={IconInvalidLight}
                            alt="invalid-light"
                            width={19}
                            height={19}
                          />
                        ) : null}
                      </DueInvoicesCount>
                    ) : null}
                  </StyledFilter>
                </Tooltip>
              );
            })}
          </Filters>
        </FeedSidebarHeader>

        <NotificationsList>
          {filter === 'all' ? (
            <NotificationsListHeader>
              <Txt id="navigation.notifications.all" />
            </NotificationsListHeader>
          ) : null}
          {sortedNotifications.length > 0
            ? sortedNotifications.map((notification) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  onCloseSidebar={closeSidebar}
                />
              ))
            : noNotifications}
        </NotificationsList>
      </Container>
    </Sidebar>
  );
};

const StyledFilter = styled(Filter)`
  width: 100%;
`;

const StyledHeader = styled.h3`
  flex: 1;

  text-transform: none;
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
`;

const DueInvoicesCount = styled.div`
  position: absolute;
  right: -10px;
  top: 2px;

  display: flex;

  gap: ${(props) => props.theme.margin[4]};
`;

export default FeedSidebar;
