import Big from 'big.js';

import {
  decodeAnalysisGroups,
  decodeAnalysisRows,
  toActualCostLines,
  toArrivalRows,
  toInvoiceLines,
  toPaymentProgramRowGroups,
  toProjects,
  toSnapshot,
} from '../store/actions';
import { toActualCosts } from '../store/actions/actualCost';
import { toInvoiceHeaders } from '../store/actions/invoiceHeader';
import { decodeOrderChatMessages } from '../store/actions/order/chatMessages';
import { toRevenues } from '../store/actions/revenue';
import { decodeTargetRows } from '../store/actions/target/targetRow';

import {
  RawAPIOrder,
  APIOrder,
  RawAPIOrderRow,
  APIOrderRow,
  APIUpdatedEntities,
  RawAPIUpdatedEntities,
  APIPurchaseInvoiceAttachment,
  RawAPIPurchaseInvoiceAttachment,
  APIPurchaseInvoiceAttachmentFile,
  RawAPIPurchaseInvoiceAttachmentFile,
  APIActualCostAttachmentFile,
  RawAPIActualCostAttachmentFile,
  APIWorkPackage,
  RawAPIWorkPackage,
  APIUser,
  APIProjectUser,
  RawAPIActualCostAttachment,
  APIActualCostAttachment,
  RawAPIPresettledInvoiceRow,
  APIPresettledInvoiceRow,
} from './api';
import { HandlingState } from './general';

export const mapRawPurchaseInvoiceAttachment = ({
  createdAt,
  updatedAt,
  ...rest
}: RawAPIPurchaseInvoiceAttachment): APIPurchaseInvoiceAttachment => ({
  ...rest,
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
});

export const mapRawActualCostAttachment = ({
  createdAt,
  updatedAt,
  ...rest
}: RawAPIActualCostAttachment): APIActualCostAttachment => ({
  ...rest,
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
});

export const mapRawPurchaseInvoiceAttachmentFile = ({
  createdAt,
  updatedAt,
  ...rest
}: RawAPIPurchaseInvoiceAttachmentFile): APIPurchaseInvoiceAttachmentFile => ({
  ...rest,
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
});

export const mapRawActualCostAttachmentFile = ({
  createdAt,
  updatedAt,
  ...rest
}: RawAPIActualCostAttachmentFile): APIActualCostAttachmentFile => ({
  ...rest,
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
});

export const toAPIWorkPackage = ({
  id,
  projectId,
  name,
  code,
  endDate,
  startDate,
  workPackageGroupId,
  description,
  topicIds,
  scheduleTaskIds,
  latestSnapshotTotal,
  predictionTotal,
  ordersTotal,
  receivedTotal,
  notOrderedTotal,
  predictionChangeFromLatest,
  contractTotal,
  changeOrdersTotal,
  reservesTotal,
  targetTotal,
  additionalTargetTotal,
  isDeleted,
  isSpecified,
  workPackageVirtualSpaceLinkages,
  workSectionClassId,
}: RawAPIWorkPackage): APIWorkPackage => {
  return {
    id,
    projectId,
    name,
    code,
    endDate,
    startDate,
    workPackageGroupId,
    description,
    topicIds,
    scheduleTaskIds,
    latestSnapshotTotal,
    predictionTotal: new Big(predictionTotal),
    ordersTotal: new Big(ordersTotal),
    receivedTotal: new Big(receivedTotal),
    notOrderedTotal: new Big(notOrderedTotal),
    predictionChangeFromLatest: new Big(predictionChangeFromLatest),
    contractTotal: new Big(contractTotal),
    changeOrdersTotal: new Big(changeOrdersTotal),
    reservesTotal: new Big(reservesTotal),
    targetTotal: new Big(targetTotal),
    additionalTargetTotal: new Big(additionalTargetTotal),
    isDeleted,
    isSpecified,
    workPackageVirtualSpaceLinkages,
    workSectionClassId,
  };
};

export const mapRawOrder = ({
  visibleCode,
  targetTotal,
  additionalTargetTotal,
  predictionTotal,
  contractTotal,
  receivedTotal,
  reservesTotal,
  predictionChangeFromLatest,
  changeOrdersTotal,
  invoicesUnsettledTotal,
  invoicesUnsettledCount,
  invoiceComplaintsTotal,
  invoiceComplaintsCount,
  invoiceCorrectionsTotal,
  invoiceCorrectionsCount,
  actualcostsPendingTotal,
  actualcostsPendingCount,
  ...rest
}: RawAPIOrder): APIOrder => ({
  ...rest,
  visibleCode: visibleCode || '',
  targetTotal: new Big(targetTotal),
  additionalTargetTotal: new Big(additionalTargetTotal),
  predictionTotal: new Big(predictionTotal),
  contractTotal: new Big(contractTotal),
  receivedTotal: new Big(receivedTotal),
  reservesTotal: new Big(reservesTotal),
  predictionChangeFromLatest: new Big(predictionChangeFromLatest),
  changeOrdersTotal: new Big(changeOrdersTotal),
  invoicesUnsettledTotal: new Big(invoicesUnsettledTotal || 0),
  invoicesUnsettledCount: new Big(invoicesUnsettledCount || 0),
  invoiceComplaintsTotal: new Big(invoiceComplaintsTotal || 0),
  invoiceComplaintsCount: new Big(invoiceComplaintsCount || 0),
  invoiceCorrectionsTotal: new Big(invoiceCorrectionsTotal || 0),
  invoiceCorrectionsCount: new Big(invoiceCorrectionsCount || 0),
  actualcostsPendingTotal: new Big(actualcostsPendingTotal || 0),
  actualcostsPendingCount: new Big(actualcostsPendingCount || 0),
});

export const mapRawPresettledInvoiceRow = ({
  amount,
  ...rest
}: RawAPIPresettledInvoiceRow): APIPresettledInvoiceRow => ({
  ...rest,
  amount: new Big(amount || 0),
});

export const solveHandlingState = (
  isHandleable: boolean,
  handleAmountPending: Big,
  handleVatAmountPending: Big,
  isMissingArrivalRowAccount?: boolean,
  isMissingArrivalRowVat?: boolean,
  isIncorrectVatAmount?: boolean,
  isMissingCostType?: boolean,
  isUsingNonSpecifiedWorkPackages?: boolean
): HandlingState => {
  if (isHandleable) {
    return 'CanBeHandled';
  }

  // Not handleable.
  const netPending = new Big(handleAmountPending);
  const vatPending = new Big(handleVatAmountPending);

  if (isMissingArrivalRowAccount || isMissingArrivalRowVat) {
    // We have received enough money.
    if (isMissingArrivalRowAccount && !isMissingArrivalRowVat) {
      return 'MissingAccount';
    }

    if (!isMissingArrivalRowAccount && isMissingArrivalRowVat) {
      return 'MissingVat';
    }

    return 'MissingAccountAndVat';
  }

  if (netPending.gt(0)) {
    return 'TooMuchReceived';
  }

  if (netPending.lt(0)) {
    return 'TooLittleReceived';
  }

  if (
    isIncorrectVatAmount &&
    vatPending.abs().toNumber() >= netPending.abs().toNumber()
  ) {
    return 'VatAmountIncorrect';
  }

  if (isMissingCostType) {
    return 'MissingCostType';
  }

  if (isUsingNonSpecifiedWorkPackages) {
    return 'NonSpecifiedWorkPackages';
  }

  return 'UndefinedHandlingError';
};

export const mapRawOrderRow = ({
  quantity,
  unitPrice,
  arrivalQuantity,
  arrivalTotal,
  ...rest
}: RawAPIOrderRow): APIOrderRow => ({
  ...rest,
  quantity: new Big(quantity || 0),
  unitPrice: new Big(unitPrice || 0),
  arrivalQuantity: new Big(arrivalQuantity || 0),
  arrivalTotal: new Big(arrivalTotal || 0),
});

export const mapRawUpdatedEntities = async (
  entities: RawAPIUpdatedEntities
): Promise<APIUpdatedEntities> => ({
  revenues:
    entities.paymentProgramRows &&
    (await toRevenues(entities.paymentProgramRows)),
  orders: entities.orders && entities.orders.map(mapRawOrder),
  orderRows: entities.orderRows && entities.orderRows.map(mapRawOrderRow),
  arrivals: entities.arrivals,
  arrivalRows:
    entities.arrivalRows === undefined
      ? undefined
      : await toArrivalRows(entities.arrivalRows),
  projects: entities.projects && (await toProjects(entities.projects)),
  procurementAreas: entities.procurementAreas,
  purchaseInvoiceHeaders:
    entities.purchaseInvoiceHeaders &&
    (await toInvoiceHeaders(entities.purchaseInvoiceHeaders)),
  purchaseInvoiceLines:
    entities.purchaseInvoiceLines &&
    (await toInvoiceLines(entities.purchaseInvoiceLines)),
  purchaseInvoiceAttachments:
    entities.purchaseInvoiceAttachments &&
    entities.purchaseInvoiceAttachments.map(mapRawPurchaseInvoiceAttachment),
  purchaseInvoiceAttachmentFiles:
    entities.purchaseInvoiceAttachmentFiles &&
    entities.purchaseInvoiceAttachmentFiles.map(
      mapRawPurchaseInvoiceAttachmentFile
    ),
  actualCosts:
    entities.actualCosts && (await toActualCosts(entities.actualCosts)),
  actualCostAttachments:
    entities.actualCostAttachments &&
    entities.actualCostAttachments.map(mapRawActualCostAttachment),
  actualCostAttachmentFiles:
    entities.actualCostAttachmentFiles &&
    entities.actualCostAttachmentFiles.map(mapRawActualCostAttachmentFile),
  actualCostsDetailLines:
    entities.actualCostsDetailLines &&
    (await toActualCostLines(entities.actualCostsDetailLines)),
  topics: entities.topics,
  tasks: entities.tasks,
  notificationFeedItems: entities.notificationFeedItems,
  targetRows:
    entities.targetRows === undefined
      ? undefined
      : await decodeTargetRows(entities.targetRows),
  analysisGroups:
    entities.analysisDefinitions === undefined
      ? undefined
      : await decodeAnalysisGroups(entities.analysisDefinitions),
  analysisRows:
    entities.analysisListItems === undefined
      ? undefined
      : await decodeAnalysisRows(entities.analysisListItems),
  workPackages:
    entities.workPackages && entities.workPackages.map(toAPIWorkPackage),
  workPackageGroups: entities.workPackageGroups,
  snapshots: entities.snapshots && (await toSnapshot(entities.snapshots)),
  orderChatMessages:
    entities.orderChatMessages === undefined
      ? undefined
      : await decodeOrderChatMessages(entities.orderChatMessages),
  paymentProgramRowGroups:
    entities.paymentProgramRowGroups === undefined
      ? undefined
      : await toPaymentProgramRowGroups(entities.paymentProgramRowGroups),
  presettledInvoiceRows:
    entities.presettledInvoiceRows === undefined
      ? undefined
      : entities.presettledInvoiceRows.map(mapRawPresettledInvoiceRow),
});

export const toAPIProjectUser = ({
  id,
  name,
  emails,
  roles,
}: APIUser): APIProjectUser => {
  return {
    id,
    name,
    emails,
    display: name,
    roles,
  };
};
