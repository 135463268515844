import React from 'react';

import Select from 'rc-select';
import {
  OptionData,
  OptionGroupData,
  OptionsType,
} from 'rc-select/lib/interface';
import styled from 'styled-components';

import useTxt from '../hooks/useTxt';

import { IconDown } from '../assets';

import defaultTheme from '../styles/theme';

type DropDownSelectProps = {
  className?: string;
  options: OptionsType;
  onChange: (selection: string, primary?: boolean) => void;
  onSelectEnabled?: boolean;
  defaultValue?: string;
  value?: string;
  notFound?: string;
  label?: string;
  hideBorder?: boolean;
  optionLabelProp?: string;
  optionFilterProp?: string;
  dropdownMatchSelectWidth?: boolean | number;
  disabled?: boolean;
  id?: string;
  additionalStyles?: React.CSSProperties;
  additionalContainerStyles?: React.CSSProperties;
  required?: boolean;
  invalid?: boolean;
  placeholder?: React.ReactNode;
  dropdownAlign?: any;
  dropdownStyle?: React.CSSProperties;
  listHeight?: number;
};

const inputBackgroundColor = (
  additionalContainerStyles?: React.CSSProperties,
  disabled?: boolean
) => {
  if (disabled) {
    return defaultTheme.color.graphiteB91;
  }

  if (additionalContainerStyles?.backgroundColor) {
    return additionalContainerStyles.backgroundColor;
  }

  return 'white';
};

const Container = styled.div<{
  hideBorder?: boolean;
  required?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  additionalContainerStyles?: React.CSSProperties;
}>`
  /* stylelint-disable selector-max-type -- Third party libraries need to be styled either by type or by classnames */
  input {
    border-radius: 0.25rem;

    padding: ${({ additionalContainerStyles }) =>
      additionalContainerStyles?.padding ?? `0.25rem`};

    width: 100%;
    height: ${({ additionalContainerStyles }) =>
      additionalContainerStyles?.height ?? `1.5rem`};

    background-color: ${({ additionalContainerStyles, disabled }) =>
      inputBackgroundColor(additionalContainerStyles, disabled)};
    background-image: url(${IconDown});
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;

    font-size: 0.875rem;
    line-height: 0.875rem;
    color: ${({ theme }) => theme.color.pitch};

    cursor: default;

    ${({ hideBorder }) =>
      hideBorder
        ? `border: none;`
        : `border-width: 1px;
        border-style: solid;
      `}

    ${({ theme, required, invalid }) =>
      `${
        required
          ? `border-color: ${
              invalid
                ? `${theme.color.errorNotification}`
                : `${theme.color.dropdownBorder}`
            };
            `
          : `border-color: ${theme.color.dropdownBorder}`
      }`}
  }

  /* Copied and modified from
   * https://github.com/react-component/select/blob/master/assets/index.less
   * List styling is in global.ts.
   */

  /* stylelint-disable selector-max-class -- Third party libraries need to be styled either by type or by classnames */
  .rc-select-selector {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .rc-select-selector .rc-select-selection-search {
    width: 100%;
  }

  .rc-select-selector .rc-select-selection-item,
  .rc-select-selector .rc-select-selection-placeholder {
    position: absolute;
    left: 0;
    right: 1.5rem;

    margin: ${({ additionalContainerStyles }) =>
      additionalContainerStyles?.margin ?? `0.25rem 0rem 0.25rem 0.25rem `};

    height: auto;

    background-color: ${({ additionalContainerStyles, disabled }) =>
      inputBackgroundColor(additionalContainerStyles, disabled)};

    font-weight: normal;
    white-space: nowrap;
    color: ${({ theme, invalid }) =>
      invalid ? theme.color.errorNotification : theme.color.pitch};

    overflow: hidden;
    pointer-events: none;
  }

  ${({ additionalContainerStyles }) =>
    `${
      additionalContainerStyles?.width
        ? `width: ${additionalContainerStyles?.width};`
        : ''
    }`}
`;

const DropDownSelect = ({
  className,
  options,
  defaultValue,
  value,
  onChange,
  onSelectEnabled,
  notFound,
  label,
  hideBorder,
  optionLabelProp,
  optionFilterProp = 'label',
  dropdownMatchSelectWidth = true,
  disabled = false,
  id,
  additionalStyles,
  additionalContainerStyles,
  required,
  invalid,
  placeholder,
  dropdownAlign,
  dropdownStyle = { minWidth: '150px' },
  listHeight,
}: DropDownSelectProps) => {
  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  function determineIfOptionOrOptionGroup(
    data: OptionData | OptionGroupData
  ): data is OptionGroupData {
    if ((data as OptionGroupData).options) {
      return true;
    }

    return false;
  }

  const optionsFromGroups = options.map((item) => {
    if (determineIfOptionOrOptionGroup(item)) {
      return item.options;
    }

    return item;
  });

  const flattenedOptions = optionsFromGroups.flat();

  const primaryOptions = options
    .filter((o) => o.primary)
    .map((o) => {
      const { primary: _, ...rest } = o;

      return rest;
    });

  const otherOptions = options
    .filter((o) => !o.primary)
    .map((o) => {
      const { primary: _, ...rest } = o;

      return rest;
    });

  const separatorTxt = useTxt('common.otherSections');

  const getGroupSeparator = () => {
    return (primaryOptions.length > 0 && otherOptions.length > 0
      ? [
          {
            label: `-- ${separatorTxt} --`,
            options: [],
          },
        ]
      : []) as OptionsType;
  };

  const sortedOptions = [
    ...primaryOptions,
    ...getGroupSeparator(),
    ...otherOptions,
  ];

  return (
    <Container
      aria-label={label}
      className={className}
      onKeyPress={stopPropagation}
      hideBorder={hideBorder}
      additionalContainerStyles={additionalContainerStyles}
      required={required}
      invalid={invalid}
      disabled={disabled}
    >
      <Select
        id={id}
        disabled={disabled}
        dropdownStyle={dropdownStyle}
        placeholder={placeholder}
        // eslint-disable-next-line no-inline-styles/no-inline-styles
        style={additionalStyles}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        getPopupContainer={(triggerNode: HTMLElement) =>
          (triggerNode?.parentNode as HTMLElement) || document.body
        }
        onClick={stopPropagation}
        showArrow={false}
        showSearch
        listHeight={listHeight}
        dropdownAlign={dropdownAlign}
        filterSort={(a, b): number => {
          const aFilterProp = a[optionFilterProp];
          const bFilterProp = b[optionFilterProp];

          return aFilterProp && bFilterProp
            ? aFilterProp.toString().localeCompare(bFilterProp.toString())
            : 0;
        }}
        optionFilterProp={optionFilterProp}
        optionLabelProp={optionLabelProp}
        defaultValue={
          defaultValue === undefined
            ? undefined
            : flattenedOptions.find((option) => option.value === defaultValue)
                ?.value
        }
        value={
          value === undefined
            ? undefined
            : flattenedOptions.find((option) => option.value === value)?.value
        }
        onChange={(selection) => {
          // Only pass valid selections.
          const val = flattenedOptions.find(
            (option) => option.value === selection
          );

          if (val) {
            onChange(val.value.toString(), val.primary);
          }
        }}
        onSelect={
          onSelectEnabled
            ? (selection) => {
                // Only pass valid selections.
                const val = flattenedOptions.find(
                  (option) => option.value === selection
                )?.value;

                if (val) {
                  onChange(val.toString());
                }
              }
            : undefined
        }
        options={sortedOptions}
        notFoundContent={notFound}
      />
    </Container>
  );
};

export default DropDownSelect;
