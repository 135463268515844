import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import { getProjectOrders } from '../../../store/reducers/order/order';
import { getSortOrderFor } from '../../../store/reducers/order/sortOrders';
import { getProcurementAreasByProjectId } from '../../../store/reducers/procurementArea';
import { APIProject, getProject } from '../../../store/reducers/project';
import {
  getAllProjectProcurementAreasAreOpen,
  getColumns,
  getBillingFilteringActive,
  getSelectedOrderStatuses,
} from '../../../store/reducers/ui';

import {
  fetchOrdersForProject,
  orderSortToggled,
} from '../../../store/actions';
import { fetchProjects } from '../../../store/actions/project';
import {
  uiStateCloseAllProcurementAreas,
  uiStateOpenSeveralProcurementAreas,
  uiStateToggleBillingProcurementAreas,
} from '../../../store/actions/ui';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useRemoteData from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import {
  BigPriceValue,
  DeltaBigCostValue,
  DeltaBigProfitValue,
} from '../../../components/BigValue';
import { IconButton } from '../../../components/Buttons';
import Cell from '../../../components/Cell';
import { Spinner } from '../../../components/Loading';
import ColumnSettings from '../../../components/MultiSelect/MultiSelect';
import RemoteData from '../../../components/RemoteData';
import {
  TableHeader,
  StickyHeaderTh,
  HeaderNumber,
  HeaderName,
} from '../../../components/Table';
import Txt from '../../../components/Txt';
import UnsettledCostsButton from '../../../components/UnsettledCostsButton';

import { IconFunnel, IconFunnelBlack } from '../../../assets';
import {
  IconDoubleRight,
  IconDoubleDown,
  IconCogwheel,
  IconCloseBlack,
  IconSortDown,
  IconSortUp,
} from '../../../assets/svg';

import { ColumnType } from '../../../store/localStorage';
import { FilterDropdown } from './StatusFilterDropDown';

type ProcurementAreasTableHeaderProps = {
  projectId: string;
  onChangeColumn: (column: any) => void;
  toggleOrderStatusFilter: (status: string) => void;
  orderStatusFilterValues: string[];
  billingFilteringActive: boolean;
};

const HeaderRow = styled.tr`
  height: 70px;
`;

const ProcurementAreasTableHeader = ({
  projectId,
  onChangeColumn,
  toggleOrderStatusFilter,
  orderStatusFilterValues,
  billingFilteringActive,
}: ProcurementAreasTableHeaderProps) => {
  const { projectColumns } = useSelector(getColumns);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showStatusDropdown, setShowStatusDropdown] = useState<boolean>(false);

  const dispatch = useDispatch();
  const totals = useSelector(getProject(projectId));

  const billingFilterIsActive = billingFilteringActive;

  const allProcurementAreas = useSelector(
    getProcurementAreasByProjectId(projectId)
  );

  const allAreasOpen = useSelector(
    getAllProjectProcurementAreasAreOpen(projectId)
  );

  const toggleAllOpenStatus = () => {
    if (allAreasOpen) {
      dispatch(uiStateCloseAllProcurementAreas(projectId));
    } else {
      dispatch(
        uiStateOpenSeveralProcurementAreas(
          allProcurementAreas.map((area) => area.id)
        )
      );
    }
  };

  const secondaryHeaderText = useTxt(
    'project.procurementTable.secondaryHeader.text'
  );

  const orderStatusText = useTxt('project.procurementTable.order.status');
  const allOpenText = useTxt('project.procurementTable.areas.open');
  const allClosedText = useTxt('project.procurementTable.areas.closed');
  const functionsText = useTxt('project.procurementTable.header.functions');

  const showHideColumns = useTxt('common.showHide');

  const toggleSetting = () => {
    setShowSettings(!showSettings);
  };

  const toggleShowStatusDropdown = () => {
    setShowStatusDropdown(!showStatusDropdown);
  };

  const ref = useOnClickOutside(() => {
    if (showStatusDropdown) {
      toggleShowStatusDropdown();
    }
  });

  const onOrderStatusThClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    toggleShowStatusDropdown();
  };

  return (
    <TableHeader>
      <RemoteData
        fetchData={fetchProjects()}
        data={totals}
        loadingElement={<Spinner size="auto" />}
      >
        {(project) => {
          return (
            <>
              <HeaderRow>
                <StyledStickyTh
                  width="2rem"
                  textAlign="left"
                  aria-label={orderStatusText}
                >
                  <IconButton
                    icon={allAreasOpen ? IconDoubleDown : IconDoubleRight}
                    onClick={toggleAllOpenStatus}
                    aria-label={allAreasOpen ? allOpenText : allClosedText}
                  />
                </StyledStickyTh>
                {projectColumns
                  .filter((item) => item.selected)
                  .map((column) => {
                    return (
                      <VerticalAlignTh
                        key={column.id}
                        textAlign={column.id === '1' ? 'left' : 'right'}
                        colSpan={column.id === '2' ? 2 : 1}
                        width={column.width || '7rem'} // defaults to 7rem
                        verticalAlign={
                          column.id === '1' ? undefined : 'baseline'
                        }
                        onClick={
                          column.id === '14' ? onOrderStatusThClick : undefined
                        }
                        ref={column.id === '14' ? ref : undefined}
                      >
                        <HeaderCellContents
                          column={column}
                          projectId={projectId}
                          project={project}
                          filterValue={orderStatusFilterValues}
                          billingFilterIsActive={billingFilterIsActive}
                        />
                        {showStatusDropdown && column.id === '14' ? (
                          <FilterDropdown
                            setFilterValue={toggleOrderStatusFilter}
                            filterValue={orderStatusFilterValues}
                          />
                        ) : null}
                      </VerticalAlignTh>
                    );
                  })}
                <StyledStickyTh aria-label={functionsText} width="8rem">
                  <IconButton
                    onClick={toggleSetting}
                    icon={showSettings ? IconCloseBlack : IconCogwheel}
                    id="project.button.columnSettings"
                    style={btnstyle}
                    data-cy="column-settings"
                  />
                  {showSettings ? (
                    <ColumnSettings
                      toggleShow={toggleSetting}
                      listItems={{
                        title: showHideColumns,
                        listItemsArray: projectColumns ?? [],
                      }}
                      onChange={onChangeColumn}
                    />
                  ) : null}
                </StyledStickyTh>
              </HeaderRow>
              {orderStatusFilterValues.length === 0 &&
              !billingFilterIsActive ? null : (
                <tr>
                  <SecondaryHeaderRowCell />
                  <SecondaryHeaderRowCell align="left" colSpan={3}>
                    {secondaryHeaderText}
                  </SecondaryHeaderRowCell>
                  {projectColumns
                    .filter((item) => item.selected)
                    .map((column) => {
                      return (
                        <FilteredHeaderCellValue
                          key={`filtered-header-cell-value-${column.id}`}
                          column={column}
                          projectId={projectId}
                          orderStatusFilterValues={orderStatusFilterValues}
                          billingFilterIsActive={billingFilterIsActive}
                        />
                      );
                    })}
                  <SecondaryHeaderRowCell colSpan={2} />
                </tr>
              )}
            </>
          );
        }}
      </RemoteData>
    </TableHeader>
  );
};

type HeaderCellContentsProps = {
  column: ColumnType;
  projectId: string;
  project: APIProject | undefined;
  filterValue: string[];
  billingFilterIsActive: boolean;
};

const HeaderCellContents = ({
  column,
  projectId,
  project,
  filterValue,
  billingFilterIsActive,
}: HeaderCellContentsProps) => {
  const dispatch = useDispatch();

  const allAreasOpen = useSelector(
    getAllProjectProcurementAreasAreOpen(projectId)
  );

  const allProcurementAreas = useSelector(
    getProcurementAreasByProjectId(projectId)
  );

  const sortOrder = useSelector(getSortOrderFor('visibleCode'));

  const toggleAllOpenStatus = () => {
    if (allAreasOpen) {
      dispatch(uiStateCloseAllProcurementAreas(projectId));
    } else {
      dispatch(
        uiStateOpenSeveralProcurementAreas(
          allProcurementAreas.map((area) => area.id)
        )
      );
    }
  };

  const sortAscText = useTxt('project.procurementTable.orders.asc');
  const sortDescText = useTxt('project.procurementTable.orders.desc');

  const toggleSort = () => {
    if (!allAreasOpen) {
      toggleAllOpenStatus();
    }

    dispatch(orderSortToggled({ sortableKey: 'visibleCode' }));
  };

  const getHeaderNumber = (columnId: string): Big | null => {
    const costPlan = project?.costPredictionTotal ?? new Big('0');
    const target = project?.targetTotal ?? new Big('0');
    const additionalTarget = project?.additionalTargetTotal ?? new Big('0');
    const acceptedTotal = project?.receivedTotal ?? new Big('0');

    switch (columnId) {
      case '3':
        return target;

      case '4':
        return target.minus(costPlan) ?? new Big('0');
      case '5':
        return costPlan;

      case '6':
        return project?.costPredictionChangeFromLatest ?? new Big('0');
      case '7':
        return project?.contractTotal ?? new Big('0');
      case '8':
        return project?.changeOrdersTotal ?? new Big('0');
      case '9':
        return project?.reservesTotal ?? new Big('0');
      case '11':
        return acceptedTotal;
      case '12':
        return costPlan.minus(acceptedTotal);
      case '13':
        return new Big('0');
      case '15':
        return additionalTarget;

      default:
        return null;
    }
  };

  const alt = useTxt('comments.modal.changes.filter');

  const filterImageSrc = filterValue.length > 0 ? IconFunnelBlack : IconFunnel;
  const invoiceImageSrc = billingFilterIsActive ? IconFunnelBlack : IconFunnel;
  switch (column.id) {
    case '2': {
      return null;
    }
    case '1': {
      return (
        <IconButton
          icon={sortOrder === 'Ascending' ? IconSortDown : IconSortUp}
          onClick={toggleSort}
          aria-label={sortOrder === 'Ascending' ? sortAscText : sortDescText}
        />
      );
    }
    case '13': {
      return (
        <>
          <FlexHeaderName>
            <Txt id={column.name} />
            {column.id === '13' ? (
              <StyledImg
                src={invoiceImageSrc}
                alt={alt}
                onClick={() =>
                  dispatch(uiStateToggleBillingProcurementAreas(''))
                }
              />
            ) : null}
          </FlexHeaderName>
          {getHeaderNumber(column.id) ? (
            <HeaderNumber>
              <HeaderValue
                columnId={column.id}
                headerNumber={getHeaderNumber(column.id)}
              />
            </HeaderNumber>
          ) : null}
        </>
      );
    }
    case '14': {
      return (
        <>
          <FlexHeaderName>
            <Txt id={column.name} />
            {column.id === '14' ? (
              <StyledImg src={filterImageSrc} alt={alt} />
            ) : null}
          </FlexHeaderName>
          {getHeaderNumber(column.id) ? (
            <HeaderNumber>
              <HeaderValue
                columnId={column.id}
                headerNumber={getHeaderNumber(column.id)}
              />
            </HeaderNumber>
          ) : null}
        </>
      );
    }
    default: {
      return (
        <>
          <HeaderName>
            <Txt id={column.name} />
            {column.id === '14' ? (
              <StyledImg src={filterImageSrc} alt={alt} />
            ) : null}
          </HeaderName>
          {getHeaderNumber(column.id) ? (
            <HeaderNumber>
              <HeaderValue
                columnId={column.id}
                headerNumber={getHeaderNumber(column.id)}
              />
            </HeaderNumber>
          ) : null}
        </>
      );
    }
  }
};

type HeaderValueProps = {
  columnId: string;
  headerNumber: Big | null;
  orderStatusFilterValues?: string[];
  isDisabled?: boolean;
};

const HeaderValue = ({
  columnId,
  headerNumber,
  orderStatusFilterValues,
  isDisabled,
}: HeaderValueProps) => {
  /* DeltaBigCostValue shows difference in colors */

  switch (columnId) {
    case '4':
      return (
        <DeltaBigProfitValue
          value={headerNumber ?? new Big('0')}
          decimals={0}
        />
      );
    case '6':
      return (
        <DeltaBigCostValue value={headerNumber ?? new Big('0')} decimals={0} />
      );
    case '13':
      return (
        <>
          <UnsettledCostsButton
            projectLevelSummary
            orderStatusFilterValues={orderStatusFilterValues}
            documentType="invoice"
            isDisabled={isDisabled}
          />
          <UnsettledCostsButton
            projectLevelSummary
            orderStatusFilterValues={orderStatusFilterValues}
            documentType="actualCost"
            isDisabled={isDisabled}
          />
        </>
      );
    default:
      return (
        <BigPriceValue value={headerNumber ?? new Big('0')} decimals={0} />
      );
  }
};

type FilteredHeaderCellValueProps = {
  column: ColumnType;
  projectId: string;
  orderStatusFilterValues: string[];
  billingFilterIsActive: boolean;
};

const FilteredHeaderCellValue = ({
  column,
  projectId,
  orderStatusFilterValues,
  billingFilterIsActive,
}: FilteredHeaderCellValueProps) => {
  const billingFilteringActive = useSelector(getBillingFilteringActive);
  const orderStatsuFilterValues = useSelector(getSelectedOrderStatuses);

  const filteredOrders =
    useRemoteData(
      getProjectOrders(
        projectId,
        billingFilteringActive,
        orderStatsuFilterValues
      ),
      fetchOrdersForProject(projectId)
    ) ?? [];

  const getHeaderNumber = (columnId: string): Big | null => {
    const costPlan = filteredOrders.reduce(
      (previous, current) => previous.add(current.predictionTotal),
      new Big('0')
    );

    const target = filteredOrders.reduce(
      (previous, current) => previous.add(current.targetTotal),
      new Big('0')
    );

    const additionalTarget = filteredOrders.reduce(
      (previous, current) => previous.add(current.additionalTargetTotal),
      new Big('0')
    );

    const acceptedTotal = filteredOrders.reduce(
      (previous, current) => previous.add(current.receivedTotal),
      new Big('0')
    );

    const costPredictionChangeFromLatest = filteredOrders.reduce(
      (previous, current) => previous.add(current.predictionChangeFromLatest),
      new Big('0')
    );

    const contractTotal = filteredOrders.reduce(
      (previous, current) => previous.add(current.contractTotal),
      new Big('0')
    );

    const changeOrdersTotal = filteredOrders.reduce(
      (previous, current) => previous.add(current.changeOrdersTotal),
      new Big('0')
    );

    const reservesTotal = filteredOrders.reduce(
      (previous, current) => previous.add(current.reservesTotal),
      new Big('0')
    );

    switch (columnId) {
      case '3':
        return target;

      case '4':
        return target.minus(costPlan);
      case '5':
        return costPlan;

      case '6':
        return costPredictionChangeFromLatest;
      case '7':
        return contractTotal;
      case '8':
        return changeOrdersTotal;
      case '9':
        return reservesTotal;
      case '11':
        return acceptedTotal;
      case '12':
        return costPlan.minus(acceptedTotal);
      case '13':
        return new Big('0');
      case '15':
        return additionalTarget;

      default:
        return null;
    }
  };

  if (orderStatusFilterValues.length === 0 && !billingFilterIsActive) {
    return null;
  }

  return (
    <>
      {getHeaderNumber(column.id) ? (
        <SecondaryHeaderRowCell>
          <SecondaryHeaderNumber>
            <HeaderValue
              columnId={column.id}
              headerNumber={getHeaderNumber(column.id)}
              orderStatusFilterValues={orderStatusFilterValues}
              isDisabled={column.id === '13' ? true : undefined}
            />
          </SecondaryHeaderNumber>
        </SecondaryHeaderRowCell>
      ) : null}
    </>
  );
};

type VerticalAlignThProps = {
  verticalAlign?: 'baseline';
  disabled?: boolean;
};

const btnstyle = {
  height: '24px',
  width: '24px',
  borderRadius: '50%',
  backgroundColor: '#f2f2f2',
  border: '1px solid',
  position: 'absolute',
  padding: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 10,
  top: 40,
  zIndex: 3,
} as React.CSSProperties;

const SecondaryHeaderRowCell = styled(Cell)`
  position: sticky;
  top: 70px;
  background: ${(props) => props.theme.color.graphiteB76};
  z-index: 1;
`;

const SecondaryHeaderNumber = styled(HeaderNumber)`
  padding: 0;
  color: ${(props) => props.theme.color.black};
`;

const StyledStickyTh = styled(StickyHeaderTh)`
  z-index: 2;
`;

const VerticalAlignTh = styled(StyledStickyTh)<VerticalAlignThProps>`
  cursor: ${(props) =>
    typeof props.onClick === 'function' ? 'pointer' : 'default'};
  vertical-align: ${(props) => props.verticalAlign ?? 'inherit'};
`;

const StyledImg = styled.img`
  margin-left: ${({ theme: { margin } }) => margin[8]};
  height: 1rem;
  cursor: pointer;
`;

const FlexHeaderName = styled(HeaderName)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ProcurementAreasTableHeader;
